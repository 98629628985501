import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Company } from "./Redux/CompanySlice";
import { populate_Facility } from "./Redux/FacilitySlice";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import AddPageButton from "../Button/AddPageButton";
import ViewPageButton from "../Button/ViewPageButton";
import EditPageButton from "../Button/EditPageButton";
import ManageSearch from "../Constant/ManageSearch";
import SuccessMessage from "../Constant/SuccessMessage";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function Facility() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheScreensToolTips();
        window.addEventListener("click", (e) => {
          if (e.target.id != "Customer") {
            setCustomer(false);
          }
        });
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster?size=1000",
          "Get"
        );
      }
    }, 2000);
    // console.log(window.innerHeight);
    // setinnerHeight(window.innerHeight)
    switch (localStorage.getItem("header")) {
      case "Products":
        navigate("/Products");
        break;
      case "ProductionProcess":
        navigate("/ProductionProcess");
        break;
      case "QualityProcess":
        navigate("/QualityProcess");
        break;
      case "Forms":
        navigate("/Forms");
        break;
      case "DefectCodes":
        navigate("/DefectCodes");
        break;
      case "FailureCodes":
        navigate("/FailureCodes");
        break;
      case "SeverityCodes":
        navigate("/SeverityCodes");
        break;
      case "InspectionParameters":
        navigate("/InspectionParameters");
        break;
      case "dashboards":
        navigate("/dashboards");
        break;
      case "RoleHierarchy":
        navigate("/RoleHierarchy");
        break;
      case "Staff":
        navigate("/Staff");
        break;
      case "Equipment":
        navigate("/Equipment");
        break;
      case "Sensor":
        navigate("/Sensor");
        break;
      case "Complaint":
        navigate("/Complaint");
        break;
      case "Roster":
        navigate("/Roster");
        break;
      case "Company":
        navigate("/Company");
        break;
      case "Supplier":
        navigate("/Supplier");
        break;
      case "Parts":
        navigate("/Parts");
        break;
      case "Profile":
        navigate("/Profile");
        break;
      case "BOM":
        navigate("/BOM");
        break;
      case "CustomerProduct":
        navigate("/CustomerProduct");
        break;
      case "TestCertficate":
        navigate("/TestCertficate");
        break;
      case "TestCertificateValidation":
        navigate("/TestCertificateValidation");
        break;
      case "EventRules":
        navigate("/EventRules");
        break;
      case "EventTriggers":
        navigate("/EventTriggers");
        break;
      case "Notifications":
        navigate("/Notifications");
        break;
      case "ReleaseNotes":
        navigate("/ReleaseNotes");
        break;
      case "ContactSupport":
        navigate("/ContactSupport");
        break;
    }
  }, []);
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    // if (clientSideValidations.length == 0) {
    axios
      .get(
        process.env.REACT_APP_BASE_API_PYTHON +
          "clientSideValidations?size=1000"
      )
      .then((response) => {
        if (response.status === 200) {
          let tempClientSideValidation = [];
          for (let i = 0; i < response.data.length; i++) {
            tempClientSideValidation.push(response.data[i]);
          }
          dispatch(populate_client_side_validation(tempClientSideValidation));
        }
      });
    // }
  };
  const [searchedCustomerArray, setSearchedCustomerArray] = useState([]);
  const [searchCustomer, setSearchCustomer] = useState("");
  const onChangeSearchCustomer = (e) => {
    let content = e.target.value;
    setSearchCustomer(content.toLowerCase());
    let temp = customerArray.filter((e) =>
      e.name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setSearchedCustomerArray(temp);
    // console.log(temp);
  };
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const AddRemoveCustomers = (id) => {
    let tempSelectedCustomer = [...selectedCustomers];
    let customerIndex = tempSelectedCustomer.findIndex(
      (e) => e.companyDbId == id
    );
    if (customerIndex == -1) {
      tempSelectedCustomer.push(companyMaster.find((e) => e.companyDbId == id));
    } else {
      tempSelectedCustomer.splice(customerIndex, 1);
    }
    setSelectedCustomers(tempSelectedCustomer);
  };

  let companyMaster = useSelector((state) => state.callCompany.value);

  //Get The Client Side Validations

  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  const facilities = useSelector((state) => state.callFacilities.value);
  const [customerArray, setCustomerArray] = useState([]);
  const FetchTheCompany = (_facility) => {
    if (companyMaster.length == 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "companyMaster")
        .then((response) => {
          if (response.status == 200) {
            let tempCompanyMaster = [];
            let tempCustomerArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempCompanyMaster.push(response.data[i]);
                if (
                  tempCustomerArray.findIndex(
                    (e) => e === response.data[i].name
                  ) == -1
                ) {
                  tempCustomerArray.push(response.data[i].name);
                }
              }
            }
            dispatch(populate_Company(tempCompanyMaster));
            let tempCustomer = [];
            for (let i = 0; i < tempCompanyMaster.length; i++) {
              if (
                _facility.findIndex(
                  (e) => e.companyDbId == tempCompanyMaster[i].companyDbId
                ) != -1
              ) {
                tempCustomer.push(tempCompanyMaster[i]);
              }
            }
            setCustomerArray(tempCustomer);
            setSelectedCustomers(tempCustomer);
          }
        });
    } else {
      let tempCustomer = [];
      for (let i = 0; i < companyMaster.length; i++) {
        if (
          _facility.findIndex(
            (e) => e.companyDbId == companyMaster[i].companyDbId
          ) != -1
        ) {
          tempCustomer.push(companyMaster[i]);
        }
      }
      setSelectedCustomers(tempCustomer);
      setCustomerArray(tempCustomer);
    }
  };

  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "facilityMaster";

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [facilityMasterObjectErrors, setFacilityMasterObjectErrors] = useState({
    facilityCodeError: "",
    nameError: "",
    acronymError: "",
    typeError: "",
    descriptionError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    lineError: "",
    floorError: "",
    areaError: "",
    moduleError: "",
    machineError: "",
    effDateFromError: "",
    effDateToError: "",
    companyNameError: "",
    createdByError: "",
    updatedByError: "",
    createdTimestampError: "",
    updatedTimestampError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "facilityCodeError");
    OnChangeErrorValues("", "nameError");
    OnChangeErrorValues("", "acronymError");
    OnChangeErrorValues("", "typeError");
    OnChangeErrorValues("", "descriptionError");
    OnChangeErrorValues("", "countryError");
    OnChangeErrorValues("", "stateError");
    OnChangeErrorValues("", "cityError");
    OnChangeErrorValues("", "lineError");
    OnChangeErrorValues("", "floorError");
    OnChangeErrorValues("", "areaError");
    OnChangeErrorValues("", "moduleError");
    OnChangeErrorValues("", "machineError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
    OnChangeErrorValues("", "companyNameError");
    OnChangeErrorValues("", "createdBy");
    OnChangeErrorValues("", "updatedBy");
    OnChangeErrorValues("", "createdTimestamp");
    OnChangeErrorValues("", "updatedTimestamp");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "facilityCodeError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          facilityCodeError: error,
        }));
        break;
      case "nameError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          nameError: error,
        }));
        break;

      case "acronymError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          acronymError: error,
        }));
        break;
      case "typeError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          typeError: error,
        }));
        break;
      case "descriptionError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          descriptionError: error,
        }));
        break;
      case "countryError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          countryError: error,
        }));
        break;
      case "stateError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          stateError: error,
        }));
        break;
      case "cityError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          cityError: error,
        }));
        break;
      case "lineError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          lineError: error,
        }));
        break;

      case "floorError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          floorError: error,
        }));
        break;
      case "areaError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          areaError: error,
        }));
        break;
      case "machineError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          machineError: error,
        }));
        break;
      case "moduleError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          moduleError: error,
        }));
        break;
      case "effDateFromError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          effDateToError: error,
        }));
        break;
      case "companyNameError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          companyNameError: error,
        }));
        break;
      case "updatedBy":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          updatedBy: error,
        }));
        break;
      case "effDateFromError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          effDateToError: error,
        }));
        break;
      case "companyNameError":
        setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          ...facilityMasterObjectErrors,
          companyNameError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = facilities.filter((e) =>
      e.name.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempFilter = [];
    for (let i = 0; i < temp.length; i++) {
      if (
        new Date(temp[i].effDateTo).getTime() ==
        new Date("9999-12-31").getTime()
      ) {
        tempFilter.push(temp[i]);
      }
    }
    let tempArray = [];
    if (tempFilter.length == 0 && e.target.value.length == 0) {
      if (facilities.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(facilities[i]);
        }
      } else {
        for (let i = 0; i < facilities.length; i++) {
          tempArray.push(facilities[i]);
        }
      }
      total_pages.current = Math.ceil(
        facilities.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (tempFilter.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(tempFilter[i]);
        }
      } else {
        for (let i = 0; i < tempFilter.length; i++) {
          tempArray.push(tempFilter[i]);
        }
      }
      total_pages.current = Math.ceil(
        tempFilter.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(tempFilter);
  };
  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [facilityMasterArray, setFacilityMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [facilityMasterObject, setFacilityMasterObject] = useState({
    facilityCode: "",
    name: "",
    acronym: "",
    type: "",
    picture: "",
    description: "",
    country: "",
    state: "",
    city: "",
    line: "",
    floor: "",
    area: "",
    module: "",
    machine: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    company: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  //Declaration Of Delete States
  const [facilityMasterObjectDelete, setFacilityMasterObjectDelete] = useState({
    facilityCode: "",
    name: "",
    acronym: "",
    type: "",
    picture: "",
    description: "",
    country: "",
    state: "",
    city: "",
    line: "",
    floor: "",
    area: "",
    module: "",
    machine: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    company: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });

  const onResetValue = () => {
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      facilityCode: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      name: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      acronym: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      type: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      description: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      country: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      state: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      city: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      line: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      floor: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      area: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      module: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      machine: "",
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      effDateFrom: new Date(),
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      effDateTo: new Date("9999-12-31"),
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      company: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "facilityCode":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          facilityCode: e.target.value,
        }));
        break;
      case "name":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          name: e.target.value,
        }));
        break;
      case "acronym":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          acronym: e.target.value,
        }));
        break;
      case "type":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          type: e.target.value,
        }));
        break;
      case "description":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          description: e.target.value,
        }));
        break;
      case "country":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          country: e.target.value,
        }));
        break;
      case "state":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          state: e.target.value,
        }));
        break;
      case "city":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          city: e.target.value,
        }));
        break;
      case "line":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          line: e.target.value,
        }));
        break;
      case "floor":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          floor: e.target.value,
        }));
        break;
      case "area":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          area: e.target.value,
        }));
        break;
      case "module":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          module: e.target.value,
        }));
        break;
      case "machine":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          machine: e.target.value,
        }));
        break;
      case "effDateFrom":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          effDateTo: e.target.value,
        }));
        break;

      case "company":
        setFacilityMasterObject((facilityMasterObject) => ({
          ...facilityMasterObject,
          company: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");

  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);

  const onClickAddItem = (event_rule_id) => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = facilities.findIndex((e) => e.facilityDbId === _code);
    select_deselect_the_record(facilities[idx]);
    set_item_to_delete(facilities[idx].facilityDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    // console.log(_code);
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          // console.log("Delete:" + response.status);
          if (response.status === 200) {
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 249)
                  .shortMessage
              }`,
              "success"
            );
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "facilityMaster?size=1000",
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup(error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e) => e.validationId == 250)
                    .shortMessage
                }`,
                "success"
              );
              // ShowMEWSPopup("✅Successfully removed the record!!", "success");
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "facilityMaster?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup(error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      facilityCode: facilityMasterObject.facilityCode,
      name: facilityMasterObject.name,
      acronym: facilityMasterObject.acronym,
      type: facilityMasterObject.type,
      description: facilityMasterObject.description,
      country: facilityMasterObject.country,
      state: facilityMasterObject.state,
      city: facilityMasterObject.city,
      line: facilityMasterObject.line,
      floor: facilityMasterObject.floor,
      area: facilityMasterObject.area,
      module: facilityMasterObject.module,
      machine: facilityMasterObject.machine,
      effDateFrom: facilityMasterObject.effDateFrom,
      effDateTo:
        status === true
          ? new Date("1900-01-01")
          : facilityMasterObject.effDateTo,
      createdTimestamp: new Date().toISOString(),
      createdBy: isAuthenticated == true ? user.name : null,
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      companyDbId: Number(facilityMasterObject.company),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          if (response.status === 201) {
            // ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 247)
                  .shortMessage
              }`,
              "Success"
            );
            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "facilityMaster?size=1000",
              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          // console.log(data);
          ShowMEWSPopup(error.message, "Error");
          return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditFacility = () => {
    let deleteData = {
      facilityCode: facilityMasterObjectDelete.facilityCode,
      name: facilityMasterObjectDelete.name,
      acronym: facilityMasterObjectDelete.acronym,
      type: facilityMasterObjectDelete.type,
      description: facilityMasterObjectDelete.description,
      country: facilityMasterObjectDelete.country,
      state: facilityMasterObjectDelete.state,
      city: facilityMasterObjectDelete.city,
      line: facilityMasterObjectDelete.line,
      floor: facilityMasterObjectDelete.floor,
      area: facilityMasterObjectDelete.area,
      module: facilityMasterObjectDelete.module,
      machine: facilityMasterObjectDelete.machine,
      effDateFrom: GetServerDate(facilityMasterObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      companyDbId: facilityMasterObject.company,
    };
    let data = {
      facilityCode: facilityMasterObject.facilityCode,
      name: facilityMasterObject.name,
      acronym: facilityMasterObject.acronym,
      type: facilityMasterObject.type,
      description: facilityMasterObject.description,
      country: facilityMasterObject.country,
      state: facilityMasterObject.state,
      city: facilityMasterObject.city,
      line: facilityMasterObject.line,
      floor: facilityMasterObject.floor,
      area: facilityMasterObject.area,
      module: facilityMasterObject.module,
      machine: facilityMasterObject.machine,
      effDateFrom: GetServerDate(facilityMasterObject.effDateFrom),
      effDateTo: GetServerDate(facilityMasterObject.effDateTo),
      createdTimestamp: new Date().toISOString(),
      createdBy: isAuthenticated == true ? user.name : null,
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
      companyDbId: facilityMasterObject.company,
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selectfacilityDbId, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(post_url, data)
              .then((response) => {
                if (response.status === 201) {
                  FetchTheData(
                    process.env.REACT_APP_BASE_API_PYTHON +
                      "facilityMaster?size=1000",
                    "Patch"
                  );
                  ShowMEWSPopup(
                    `${
                      clientSideValidations.find((e) => e.validationId == 248)
                        .shortMessage
                    }`,
                    "Success"
                  );
                  set_show_drafts(false);
                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if (multiple_codes.length > 0) {
                    set_multiple_codes([]);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          ShowMEWSPopup(error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Facility Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "facilityCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.facilityCode.length === 0
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                facilityCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              (clientSideValidations[i].validationType === "duplicate" &&
                new Date(
                  facilities.findIndex(
                    (e) => e.facilityCode === facilityMasterObject.facilityCode
                  ) !== -1
                ).getTime() == new Date("9999-12-31").getTime()) ||
              new Date(
                facilities.findIndex(
                  (e) => e.facilityCode === facilityMasterObject.facilityCode
                ) !== -1
              ).getTime() == new Date("1900-01-01").getTime()
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                facilityCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "facilityName":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.name.length === 0
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "min_length" &&
              facilityMasterObject.name.length < 3
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                nameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "country":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.country.length == 0
            ) {
              temp++;
              OnChangeErrorValues(
                clientSideValidations[i].shortMessage,
                "countryError"
              );
            }
            break;
          case "state":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.state.length === 0
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                stateError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "city":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.city.length === 0
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                cityError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "companyName":
            if (
              clientSideValidations[i].validationType === "required" &&
              facilityMasterObject.company == 0
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                companyNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          // case "effDateTo":
          //   if (
          //     clientSideValidations[i].validationType ===
          //       "greater_than_equalto_date" &&
          //     facilityMasterObject.effDateTo < facilityMasterObject.effDateFrom
          //   ) {
          //     temp++;
          //     setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
          //       ...facilityMasterObjectErrors,
          //       effDateToError: clientSideValidations[i].shortMessage,
          //     }));
          //   }
          //   break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(facilityMasterObject.effDateFrom).getTime() >=
                new Date(facilityMasterObject.effDateTo).getTime() &&
              new Date(facilityMasterObject.effDateTo).getTime() !=
                new Date("1900-01-01").getTime()
            ) {
              temp++;
              setFacilityMasterObjectErrors((facilityMasterObjectErrors) => ({
                ...facilityMasterObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      // console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          if (
            new Date(draft_array[i].effDateTo).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = facilities.length;
      let temp = 0;
      for (let i = 0; i < facilities.length; i++) {
        if (
          new Date(facilities[i].effDateTo).getTime() ===
          new Date("9999-12-31").getTime()
        ) {
          temp += 1;
        }
      }
      //Get The Total Pages
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (facilities.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < facilities.length; i++) {
          if (
            new Date(facilities[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(facilities[i]);
          }
        }
      } else {
        if (
          facilities.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          facilities.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < facilities.length;
            i++
          ) {
            if (
              new Date(facilities[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(facilities[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(facilities[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(facilities[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };
  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_facilityMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status == 200) {
          if (_action === "Get") {
            // ShowMEWSPopup("✅Successfully fetched the records", "Success");
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 246)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 382)
                    ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_facilityMaster.push(response.data[i]);
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          setFacilityMasterArray(tempNonDraftArray);
          dispatch(populate_Facility(temp_facilityMaster));
          set_draft_array(temp_draft_array);
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheCompany(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup(error.message, "Error");
        setLoading(false);
        return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };
  const [selectfacilityDbId, setSelectfacilityDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (facilityDbId, type) => {
    setSelectfacilityDbId(facilityDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(facilityDbId);
  };
  const SupplyValuesToModal = (facilityDbId) => {
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      facilityCode: facilities.find((e) => e.facilityDbId == facilityDbId)
        .facilityCode,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      facilityCode: facilities.find((e) => e.facilityDbId == facilityDbId)
        .facilityCode,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      name: facilities.find((e) => e.facilityDbId == facilityDbId).name,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      name: facilities.find((e) => e.facilityDbId == facilityDbId).name,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      acronym: facilities.find((e) => e.facilityDbId == facilityDbId).acronym,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      acronym: facilities.find((e) => e.facilityDbId == facilityDbId).acronym,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      type: facilities.find((e) => e.facilityDbId == facilityDbId).type,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      type: facilities.find((e) => e.facilityDbId == facilityDbId).type,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      description: facilities.find((e) => e.facilityDbId == facilityDbId)
        .description,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      description: facilities.find((e) => e.facilityDbId == facilityDbId)
        .description,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      country: facilities.find((e) => e.facilityDbId == facilityDbId).country,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      country: facilities.find((e) => e.facilityDbId == facilityDbId).country,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      state: facilities.find((e) => e.facilityDbId == facilityDbId).state,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      state: facilities.find((e) => e.facilityDbId == facilityDbId).state,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      city: facilities.find((e) => e.facilityDbId == facilityDbId).city,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      city: facilities.find((e) => e.facilityDbId == facilityDbId).city,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      line: facilities.find((e) => e.facilityDbId == facilityDbId).line,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      line: facilities.find((e) => e.facilityDbId == facilityDbId).line,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      floor: facilities.find((e) => e.facilityDbId == facilityDbId).floor,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      floor: facilities.find((e) => e.facilityDbId == facilityDbId).floor,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      area: facilities.find((e) => e.facilityDbId == facilityDbId).area,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      area: facilities.find((e) => e.facilityDbId == facilityDbId).area,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      module: facilities.find((e) => e.facilityDbId == facilityDbId).module,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      module: facilities.find((e) => e.facilityDbId == facilityDbId).module,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      machine: facilities.find((e) => e.facilityDbId == facilityDbId).machine,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      machine: facilities.find((e) => e.facilityDbId == facilityDbId).machine,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      effDateFrom: facilities.find((e) => e.facilityDbId == facilityDbId)
        .effDateFrom,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      effDateFrom: facilities.find((e) => e.facilityDbId == facilityDbId)
        .effDateFrom,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      effDateTo: facilities.find((e) => e.facilityDbId == facilityDbId)
        .effDateTo,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      effDateTo: facilities.find((e) => e.facilityDbId == facilityDbId)
        .effDateTo,
    }));
    setFacilityMasterObject((facilityMasterObject) => ({
      ...facilityMasterObject,
      company: facilities.find((e) => e.facilityDbId == facilityDbId)
        .companyDbId,
    }));
    setFacilityMasterObjectDelete((facilityMasterObjectDelete) => ({
      ...facilityMasterObjectDelete,
      company: facilities.find((e) => e.facilityDbId == facilityDbId)
        .companyDbId,
    }));
  };

  const [customer, setCustomer] = useState(false);
  const OnClickCustomer = () => {
    // if (facilities.length == 0) {
    //   setCustomer(false);
    // } else {
    //   setCustomer(!customer);
    //   console.log(!customer)
    // }
    setCustomer(!customer);
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortFacilitie = [...facilityMasterArray];
    if (sortStyle === "Descending") {
      let updatedFacilityMaster = SortFacilitie.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.facilityCode
            : action === "Name"
            ? a.name
            : action === "EffectiveFrom"
            ? a.effDateFrom
            : action === "EffectiveTo"
            ? a.effDateTo
            : null;
        const b_res =
          action === "Code"
            ? b.facilityCode
            : action === "Name"
            ? b.name
            : action === "EffectiveFrom"
            ? b.effDateFrom
            : action === "EffectiveTo"
            ? b.effDateTo
            : null;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedFacilityMaster);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let updatedFacilityMaster = SortFacilitie.sort((a, b) => {
        const a_res =
          action === "Code"
            ? a.facilityCode
            : action === "Name"
            ? a.name
            : action === "EffectiveFrom"
            ? a.effDateFrom
            : action === "EffectiveTo"
            ? a.effDateTo
            : null;
        const b_res =
          action === "Code"
            ? b.facilityCode
            : action === "Name"
            ? b.name
            : action === "EffectiveFrom"
            ? b.effDateFrom
            : action === "EffectiveTo"
            ? b.effDateTo
            : null;

        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedFacilityMaster);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortFacilitie.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    } else {
      for (let i = 0; i < SortFacilitie.length; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortFacilitie.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setFacilityMasterArray(SortFacilitie);
  };
  const OnOkFilter = () => {
    let temp = [...facilities];
    let updatedArray = [];
    for (let i = 0; i < selectedCustomers.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].companyDbId == selectedCustomers[i].companyDbId
          // companyMaster.find((e) => e.name == selectedCustomers[i]).companyDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ==
            new Date("9999-12-31").getTime()
          ) {
            updatedArray.push(temp[j]);
          }
          // ShowMEWSPopup("✅ Facility Matched", "Success"); // Show popup for each match
          // set_mews_popup_color("#28A745");
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setFacilityMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    setCustomer(false);
  };
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          facilityMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            facilityMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(facilityMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            facilityMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (facilityMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(facilityMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(facilityMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  const screenFacility = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);
  return (
    <>
      {isAuthenticated === true ? (
        <div className="flex flex-col">
          <Header />
          <div className="container-fluid">
            <SuccessMessage
              mews_popup_color={mews_popup_color}
              open_edit_modal={open_edit_modal}
              show_mews_popup={show_mews_popup}
              multiple_codes={multiple_codes}
              mews_message={mews_message}
            />
            {open_view_modal === false &&
            open_add_modal === false &&
            open_edit_modal === false ? (
              <>
                <ManageSearch
                  name="Facilities"
                  OnClickShowDrafts={OnClickShowDrafts}
                  show_drafts={show_drafts}
                  toolTipName={
                    screensToolTips.find((e) => e.toolTipDbId == 419)
                      ?.toolTipDesc
                  }
                  search_content={search_content}
                  onChangeSearchContent={onChangeSearchContent}
                  placeholder="Facility"
                />
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "13%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "13%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                className="Icons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                alt=""
                              />
                              Facility Code
                              <img
                                className="Icons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                className="Icons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                alt=""
                              />
                              Facility Name
                              <img
                                className="Icons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <>
                                <img className="opacity-0" alt="" />
                                Customer
                                <img
                                  id="Customer"
                                  className="Icons"
                                  onClick={() => OnClickCustomer()}
                                  src={FilterIcon}
                                  alt=""
                                />
                                <label className="2xl:hidden block">
                                  {" "}
                                  &nbsp;
                                </label>
                              </>
                              {customer ? (
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col space-y-2">
                                        <input
                                          id="Customer"
                                          type="text"
                                          placeholder="Search"
                                          value={searchCustomer}
                                          onChange={(e) =>
                                            onChangeSearchCustomer(e)
                                          }
                                        />
                                        <div
                                          id="Customer"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedCustomers(customerArray)
                                          }
                                        >
                                          <img
                                            id="Customer"
                                            className="Icons"
                                            src={
                                              customerArray.length ==
                                              selectedCustomers.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Customer">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {searchCustomer.length === 0
                                          ? customerArray.map((item, index) => (
                                              <div
                                                id="Customer"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveCustomers(
                                                    item.companyDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Customer"
                                                  className="Icons"
                                                  src={
                                                    selectedCustomers.findIndex(
                                                      (e) =>
                                                        e.companyDbId ==
                                                        item.companyDbId
                                                    ) != -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Customer">{item.name}</p>
                                              </div>
                                            ))
                                          : searchCustomer.length > 0
                                          ? searchedCustomerArray.map(
                                              (item, index) => (
                                                <div
                                                  id="Customer"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveCustomers(
                                                      item.companyDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Customer"
                                                    className="Icons"
                                                    src={
                                                      selectedCustomers.findIndex(
                                                        (e) =>
                                                          e.companyDbId ==
                                                          item.companyDbId
                                                      ) != -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Customer">
                                                    {item.name}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        className="popupfile_button"
                                        onClick={() => OnOkFilter()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Customer"
                                        className="popupfile_button"
                                        onClick={() => setSelectedCustomers([])}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </th>
                          <th>Country</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Module</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length == 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.facilityCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {companyMaster.length > 0 &&
                                  companyMaster.findIndex(
                                    (e) => e.companyDbId == item.companyDbId
                                  ) != -1
                                    ? companyMaster.find(
                                        (e) => e.companyDbId == item.companyDbId
                                      ).name
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.country}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.state}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.city}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.module}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.facilityDbId}
                                  />
                                  {/* <div className="anotherIconsSpace">
                                  <img
                                    className="Icons"
                                    onClick={() =>
                                      OpenModal(item.facilityDbId, "View")
                                    }
                                    src={ViewIcon}
                                    alt=""
                                  />
                               
                                    <img
                                      className={`Icons ${multiple_codes.length==1 && multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "opacity-100"
                                        : "opacity-0"
                                    }`}
                                      onClick={() =>
                                        OpenModal(item.facilityDbId, "Edit")
                                      }
                                      src={EditIcon}
                                      alt=""
                                    />
                        
                                  <img
                                    className={`Icons ${
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "opacity-100"
                                        : "opacity-0"
                                    }`}
                                    onClick={() =>
                                      ShowDeleteConfirmation(item.facilityDbId)
                                    }
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </div> */}
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.facilityCode}
                                </td>
                                <td
                                  className="text-left pl-3"
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {companyMaster.length > 0 &&
                                  companyMaster.findIndex(
                                    (e) => e.companyDbId == item.companyDbId
                                  ) != -1
                                    ? companyMaster.find(
                                        (e) => e.companyDbId == item.companyDbId
                                      ).name
                                    : null}
                                </td>
                                {/* <td className='text-left pl-3' style={{borderColor:multiple_codes.findIndex(e=>e===item.facilityDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.facilityDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.facilityDbId)}>{item.acronym}</td> */}
                                {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.facilityDbId)
                                }
                              >
                                {item.type}
                              </td> */}
                                {/* <td style={{borderColor:multiple_codes.findIndex(e=>e===item.facilityDbId)!==-1?"#FFFFFF":"#DAE0E4",backgroundColor:multiple_codes.findIndex(e=>e===item.facilityDbId)!==-1?"#DAE0E4":"#FFFFFF"}} onClick={()=>select_deselect_the_record(item.facilityDbId)}>{item.description}</td>  */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.country}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.state}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.city}
                                </td>
                                {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.facilityDbId)
                                }
                              >
                                {item.floor}
                              </td> */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.module}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.facilityDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.facilityCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.name}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {companyMaster.length > 0 &&
                                  companyMaster.findIndex(
                                    (e) => e.companyDbId == item.companyDbId
                                  ) != -1
                                    ? companyMaster.find(
                                        (e) => e.companyDbId == item.companyDbId
                                      ).name
                                    : null}
                                </td>
                                {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.facilityDbId)
                                }
                              >
                                {item.type}
                              </td> */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.country}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.state}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.city}
                                </td>
                                {/* <td
                                style={{
                                  borderColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#FFFFFF"
                                      : "#DAE0E4",
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.facilityDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                onClick={() =>
                                  select_deselect_the_record(item.facilityDbId)
                                }
                              >
                                {item.floor}
                              </td> */}
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {item.module}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.facilityDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(
                                      item.facilityDbId
                                    )
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.facilityDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <TableButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      screenFacility.find((e) => e.screenId === 60)?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                    multiple_codes_delete={multiple_codes.length > 1}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>View Facility Details</div>
                  <div>
                    {screenFacility.find((e) => e.screenId === 62)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Facility Code</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.facilityCode}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Facility Name</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.name}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Customer</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          companyMaster.findIndex(
                            (e) =>
                              e.companyDbId === facilityMasterObject.company
                          ) != -1
                            ? companyMaster.find(
                                (e) =>
                                  e.companyDbId === facilityMasterObject.company
                              ).name
                            : null
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Acronym</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.acronym}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Facility Type</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.type}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Description</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.description}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Country</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.country}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">State</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.state}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">City</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.city}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Line</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.line}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Floor</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.floor}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Area</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.area}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Module</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.module}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Machine</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={facilityMasterObject.machine}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Eff. Date From</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            facilityMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Eff. Date To</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            facilityMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <ViewPageButton
                  close_modal={set_open_view_modal}
                  OpenModal={OpenModal}
                  selectedDbId={selectfacilityDbId}
                />
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Edit Facility Details</div>
                  <div>
                    {screenFacility.find((e) => e.screenId === 64)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 2)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.facilityCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 17}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 2)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(17)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        disabled={false}
                        value={facilityMasterObject.facilityCode}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 4)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.nameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 18}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 4)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(18)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        disabled={false}
                        value={facilityMasterObject.name}
                        onChange={(e) => onChangeValue(e, "name", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 6)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.acronymError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 19}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 6)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(19)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.acronym}
                        onChange={(e) => onChangeValue(e, "acronym", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 8)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.companyNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 20}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 8)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(20)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={facilityMasterObject.company}
                        onChange={(e) => onChangeValue(e, "company", null)}
                      >
                        <option value={null} className="Poppins">
                          Select....
                        </option>
                        {companyMaster.length > 0
                          ? companyMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.companyDbId}>
                                  {item.name}
                                </option>
                              ) : null
                            )
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 10)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.typeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 21}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 10)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(21)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        maxLength={1}
                        value={facilityMasterObject.type}
                        onChange={(e) => onChangeValue(e, "type", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 12)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.descriptionError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 22}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 12)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(22)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.description}
                        onChange={(e) => onChangeValue(e, "description", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 14)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.countryError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 23}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 14)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(23)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.country}
                        onChange={(e) => onChangeValue(e, "country", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 16)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.stateError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 24}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 16)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(24)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.state}
                        onChange={(e) => onChangeValue(e, "state", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 18)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.cityError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 25}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 18)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(25)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.city}
                        onChange={(e) => onChangeValue(e, "city", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 20)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.lineError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 26}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 20)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(26)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.line}
                        onChange={(e) => onChangeValue(e, "line", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 394)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.floorError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 27}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 394)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(27)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.floor}
                        onChange={(e) => onChangeValue(e, "floor", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 22)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.areaError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 28}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 22)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(28)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.area}
                        onChange={(e) => onChangeValue(e, "area", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 24)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.moduleError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 29}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 24)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(29)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.module}
                        onChange={(e) => onChangeValue(e, "module", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 26)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.machineError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 30}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 26)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(30)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.machine}
                        onChange={(e) => onChangeValue(e, "machine", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 396)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.effDateFromError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 31}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 396)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(31)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            facilityMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 28)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 32}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 28)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(32)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            facilityMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <EditPageButton
                  close_modal={set_open_edit_modal}
                  EditTheItem={OnEditFacility}
                />
              </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}

            {/* //Add Popup column */}
            {open_add_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Add Facility Details</div>
                  <div>
                    {screenFacility.find((e) => e.screenId === 61)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 1)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.facilityCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 1}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 1)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(1)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        disabled={false}
                        value={facilityMasterObject.facilityCode}
                        onChange={(e) => onChangeValue(e, "facilityCode", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 3)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.nameError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 2}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 3)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(2)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        disabled={false}
                        value={facilityMasterObject.name}
                        onChange={(e) => onChangeValue(e, "name", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 5)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.acronymError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 3}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 5)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(3)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.acronym}
                        onChange={(e) => onChangeValue(e, "acronym", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 7)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.companyNameError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 4}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 7)
                            ?.toolTipDesc
                        }
                      />

                      <select
                        onPointerEnter={() => setShowTooltip(4)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={facilityMasterObject.company}
                        onChange={(e) => onChangeValue(e, "company", null)}
                      >
                        <option value={null} className="Poppins">
                          Select....
                        </option>
                        {companyMaster.length > 0
                          ? companyMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.companyDbId}>
                                  {item.name}
                                </option>
                              ) : null
                            )
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 9)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.typeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 5}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 9)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(5)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        maxLength={1}
                        value={facilityMasterObject.type}
                        onChange={(e) => onChangeValue(e, "type", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 11)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.descriptionError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 6}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 11)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(6)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.description}
                        onChange={(e) => onChangeValue(e, "description", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 13)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.countryError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 7}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 13)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(7)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.country}
                        onChange={(e) => onChangeValue(e, "country", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 15)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.stateError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 8}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 15)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(8)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.state}
                        onChange={(e) => onChangeValue(e, "state", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 17)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.cityError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 9}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 17)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(9)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.city}
                        onChange={(e) => onChangeValue(e, "city", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 19)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.lineError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 10}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 19)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(10)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.line}
                        onChange={(e) => onChangeValue(e, "line", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 393)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.floorError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 11}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 393)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(11)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.floor}
                        onChange={(e) => onChangeValue(e, "floor", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 21)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.areaError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 12}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 21)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(12)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.area}
                        onChange={(e) => onChangeValue(e, "area", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 23)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.moduleError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 13}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 23)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(13)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.module}
                        onChange={(e) => onChangeValue(e, "module", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 25)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.machineError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 14}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 25)
                            ?.toolTipDesc
                        }
                      />

                      <input
                        onPointerEnter={() => setShowTooltip(14)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={facilityMasterObject.machine}
                        onChange={(e) => onChangeValue(e, "machine", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 395)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.effDateFromError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 15}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 395)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(15)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            facilityMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateFrom
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 27)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {facilityMasterObjectErrors.effDateToError}
                        </label>
                      </div>

                      <InputToolTip
                        showTooltip={showTooltip == 16}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 27)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(16)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            facilityMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                facilityMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              facilityMasterObject.effDateTo
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <AddPageButton
                  close_modal={set_open_add_modal}
                  AddTheItem={AddTheItem}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </>
  );
}

export default Facility;
