import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_defect_codes } from "./Redux/DefectCodesSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import ViewPageButton from "../Button/ViewPageButton";
import EditPageButton from "../Button/EditPageButton";
import AddPageButton from "../Button/AddPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import ManageSearch from "../Constant/ManageSearch";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function DefectCodes() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "defectDictionary?size=1000",
          "Get"
        );
        FetchTheScreensToolTips();
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "defectDictionary";

  let defectDictionary = useSelector((state) => state.callDefectCodes.value);
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [defectCodesObjectErrors, setDefectCodesObjectErrors] = useState({
    defectCodeError: "",
    descriptionError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "defectCodeError");
    OnChangeErrorValues("", "descriptionError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "defectCodeError":
        setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
          ...defectCodesObjectErrors,
          defectCodeError: val,
        }));
        break;
      case "descriptionError":
        setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
          ...defectCodesObjectErrors,
          descriptionError: val,
        }));
        break;
      case "effDateFromError":
        setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
          ...defectCodesObjectErrors,
          effDateFromError: val,
        }));
        break;
      case "effDateToError":
        setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
          ...defectCodesObjectErrors,
          effDateToError: val,
        }));
        break;
    }
  };
  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;
  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < defectDictionary.length; i++) {
  //     if (defectDictionary[i].defectCode !== null) {
  //       if (
  //         defectDictionary[i].defectCode
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(defectDictionary[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };
  const SearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    total_pages.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = defectDictionary.filter((e1) =>
      e1.defectCode.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (defectDictionary.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(defectDictionary[i]);
        }
      } else {
        for (let i = 0; i < defectDictionary.length; i++) {
          tempArray.push(defectDictionary[i]);
        }
      }
      total_pages.current = Math.ceil(
        defectDictionary.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    set_searched_array(temp);
    setPaginatedArray(tempArray);
  };
  ///////////////////////////
  //Get The Client Side Validations
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [defectDictionaryArray, setDefectDictionaryArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [defectCodesObject, setDefectCodesObject] = useState({
    defectCode: "",
    description: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  //Declaration Of Delete States
  const [defectCodesObjectDelete, setDefectCodesObjectDelete] = useState({
    defectCode: "",
    description: "",
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });
  const onResetValue = () => {
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      defectCode: "",
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      description: "",
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      effDateFrom: new Date(),
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "defectCode":
        setDefectCodesObject((defectCodesObject) => ({
          ...defectCodesObject,
          defectCode: e.target.value,
        }));
        break;
      case "description":
        setDefectCodesObject((defectCodesObject) => ({
          ...defectCodesObject,
          description: e.target.value,
        }));
        break;
      case "effDateFrom":
        setDefectCodesObject((defectCodesObject) => ({
          ...defectCodesObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setDefectCodesObject((defectCodesObject) => ({
          ...defectCodesObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);
  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          defectDictionaryArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            defectDictionaryArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(defectDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            defectDictionaryArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (defectDictionaryArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(defectDictionaryArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(defectDictionaryArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };
  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = defectDictionary.findIndex((e) => e.defectDbId === _code);
    select_deselect_the_record(defectDictionary[idx]);
    set_item_to_delete(defectDictionary[idx].defectDbId);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };
  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            // ShowMEWSPopup("✅Successfully removed the record!!", "success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 304)
                  ?.shortMessage
              }`,
              "Success"
            );
            set_show_drafts(false)
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "defectDictionary?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup( error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              // ShowMEWSPopup("✅Successfully removed the record!!", "success");
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e) => e.validationId == 305)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_show_drafts(false)
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON +
                  "defectDictionary?size=1000",

                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup( error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, {
          defectCode: defectCodesObject.defectCode,
          description: defectCodesObject.description,
          effDateFrom: defectCodesObject.effDateFrom,
          effDateTo:
            status === true
              ? new Date("1900-01-01")
              : defectCodesObject.effDateTo,
          createdBy: isAuthenticated == true ? user.name : "",
          createdTimestamp: new Date().toISOString(),
          updatedBy: isAuthenticated == true ? user.name : "",
          updatedTimestamp: new Date().toISOString(),
        })
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            // ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 302)
                  ?.shortMessage
              }`,
              "Success"
            );
            set_mews_popup_color("#28A745");
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "defectDictionary?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup( error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditDefect = () => {
    let deleteData = {
      defectCode: defectCodesObjectDelete.defectCode,
      description: defectCodesObjectDelete.description,
      effDateFrom: GetServerDate(defectCodesObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      defectCode: defectCodesObject.defectCode,
      description: defectCodesObject.description,
      effDateFrom: GetServerDate(defectCodesObject.effDateFrom),
      effDateTo: GetServerDate(defectCodesObject.effDateTo),
      createdBy: isAuthenticated == true ? user.name : "",
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : "",
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selecteddefectDbId, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(post_url, data)
              .then((response) => {
                if (response.status === 201) {
                  FetchTheData(
                    process.env.REACT_APP_BASE_API_PYTHON +
                      "defectDictionary?size=1000",

                    "Patch"
                  );
                  // ShowMEWSPopup("✅Successfully updated the item", "Success");
                  ShowMEWSPopup(
                    `${
                      clientSideValidations.find((e) => e.validationId == 303)
                        ?.shortMessage
                    }`,
                    "Success"
                  );
                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if(multiple_codes.length>0){
                    set_multiple_codes([]);
                  }
                  set_show_drafts(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Defect Dictionary") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "defectCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              defectCodesObject.defectCode.length === 0
            ) {
              temp++;
              setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
                ...defectCodesObjectErrors,
                defectCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              new Date(defectDictionary.findIndex(
                (e) => e.defectCode === defectCodesObject.defectCode
              ) != -1).getTime()===new Date("9999-12-31").getTime() ||  new Date(defectDictionary.findIndex(
                (e) => e.defectCode === defectCodesObject.defectCode
              ) != -1).getTime()===new Date("1900-01-01").getTime()
            ) {
              temp++;
              setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
                ...defectCodesObjectErrors,
                defectCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(defectCodesObject.effDateFrom).getTime() >=
                new Date(defectCodesObject.effDateTo).getTime()&& new Date(defectCodesObject.effDateTo).getTime()!=new Date("1900-01-01").getTime()
            ) {
              temp++;
              setDefectCodesObjectErrors((defectCodesObjectErrors) => ({
                ...defectCodesObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
       
          if (
            new Date(draft_array[i].effDateTo).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
          
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = defectDictionary.length;
      //Get The Total Pages
      let temp=0
      for (let i = 0; i < defectDictionary.length; i++) {
      
       if (
         new Date(defectDictionary[i].effDateTo).getTime() ===
         new Date("9999-12-31").getTime()
       ) {
         temp+=1
       }
     }
      total_pages.current = Math.ceil(
       temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (defectDictionary.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < defectDictionary.length; i++) {
          if (
            new Date(defectDictionary[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(defectDictionary[i]);
          }
        }
      } else {
        if (
          defectDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          defectDictionary.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < defectDictionary.length;
            i++
          ) {
            if (
              new Date(defectDictionary[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(defectDictionary[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(defectDictionary[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(defectDictionary[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_defectCodesObject = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];

    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action == "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 301)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 389)
                    ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_defectCodesObject.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_defect_codes(temp_defectCodesObject));
          setDefectDictionaryArray(tempNonDraftArray);
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup( error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };
  const [selecteddefectDbId, setSelecteddefectDbId] = useState("");
  //View Modal||Edit Modal
  const OpenModal = (defectDbId, type) => {
    setSelecteddefectDbId(defectDbId);
    console.log("Defect" + defectDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(defectDbId);
  };
  const SupplyValuesToModal = (defectDbId) => {
    let dateFrom = defectDictionary.find(
      (e) => e.defectDbId == defectDbId
    ).effDateFrom;
    let dateTo = defectDictionary.find(
      (e) => e.defectDbId == defectDbId
    ).effDateTo;
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      defectCode: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .defectCode,
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      description: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .description,
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      effDateFrom: dateFrom,
    }));
    setDefectCodesObject((defectCodesObject) => ({
      ...defectCodesObject,
      effDateTo: dateTo,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      defectCode: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .defectCode,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      description: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .description,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      effDateFrom: dateFrom,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      effDateTo: dateTo,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      createdBy: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .createdBy,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      updatedBy: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .updatedBy,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      createdTimestamp: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .createdTimestamp,
    }));
    setDefectCodesObjectDelete((defectCodesObjectDelete) => ({
      ...defectCodesObjectDelete,
      updatedTimestamp: defectDictionary.find((e) => e.defectDbId == defectDbId)
        .updatedTimestamp,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    console.log("Sorting:" + defectDictionaryArray);
    let SortingDefectCode = [...defectDictionaryArray];
    if (sortStyle === "Descending") {
      let UpdateDefectCode = SortingDefectCode.sort((a, b) => {
        const a_res = action === "Code" ? a.defectCode : a.description;
        const b_res = action === "Code" ? b.defectCode : b.description;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdateDefectCode);
      setSortStyle("Ascending");
    } else if (sortStyle === "Ascending") {
      let UpdateDefectCode = SortingDefectCode.sort((a, b) => {
        const a_res = action === "Code" ? a.defectCode : a.description;
        const b_res = action === "Code" ? b.defectCode : b.description;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdateDefectCode);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingDefectCode.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingDefectCode[i]);
      }
    } else {
      for (let i = 0; i < SortingDefectCode.length; i++) {
        tempArray.push(SortingDefectCode[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingDefectCode.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setDefectDictionaryArray(SortingDefectCode);
  };
  const DefectCodeScreenCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <div>
      <div className="flex flex-col">
        {isAuthenticated == true ? (
          <>
            <Header />
            
              <div className="container-fluid ">
                <SuccessMessage
                  mews_popup_color={mews_popup_color}
                  open_edit_modal={open_edit_modal}
                  show_mews_popup={show_mews_popup}
                  multiple_codes={multiple_codes}
                  mews_message={mews_message}
                />
                {open_add_modal == false &&
            open_edit_modal == false &&
            open_view_modal == false ? (<>
                <ManageSearch
                  name="Defect Dictionary"
                  OnClickShowDrafts={OnClickShowDrafts}
                  show_drafts={show_drafts}
                  toolTipName={
                    screensToolTips.find((e) => e.toolTipDbId == 433)
                      ?.toolTipDesc
                  }
                  search_content={search_content}
                  onChangeSearchContent={SearchContent}
                  placeholder="Defect Dictionary"
                />
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" className="w-[4%]" />
                        <col span="1" className="w-[32%]" />
                        <col span="1" className="w-[40%]" />
                        <col span="1" className="w-[8%]" />
                        <col span="1" className="w-[8%]" />
                        <col span="1" className="w-[8%]" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Defect Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Description
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.defectDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.defectCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.defectDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.defectDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.defectCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.defectDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.defectDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.defectCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {item.description}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.defectDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.defectDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.defectDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <TableButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      DefectCodeScreenCode.find((e) => e.screenId === 115)
                        ?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                    multiple_codes_delete={multiple_codes.length > 1}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>View Defect Code Details</div>
                    <div>
                      {
                        DefectCodeScreenCode.find((e) => e.screenId === 117)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Defect Code</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={defectCodesObject.defectCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Description</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={defectCodesObject.description}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              defectCodesObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                defectCodesObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              defectCodesObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(defectCodesObject.effDateTo).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <ViewPageButton
                    close_modal={set_open_view_modal}
                    OpenModal={OpenModal}
                    selectedDbId={selecteddefectDbId}
                  />
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Edit Defect Code Details</div>
                    <div>
                      {
                        DefectCodeScreenCode.find((e) => e.screenId === 118)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 230)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.defectCodeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 5}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 230)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(5)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={defectCodesObject.defectCode}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 232)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.descriptionError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 6}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 232)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(6)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={defectCodesObject.description}
                          onChange={(e) =>
                            onChangeValue(e, "description", null)
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 234)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.effDateFromError}
                          </label>
                        </div>

                        <InputToolTip
                          showTooltip={showTooltip == 7}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 234)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(7)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              defectCodesObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                defectCodesObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 236)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.effDateToError}
                          </label>
                        </div>

                        <InputToolTip
                          showTooltip={showTooltip == 8}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 236)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(8)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              defectCodesObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(defectCodesObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>

                  <EditPageButton
                    close_modal={set_open_edit_modal}
                    EditTheItem={OnEditDefect}
                  />
                </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}

            {/* //Add Popup column */}
            {open_add_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Add Defect Code Details</div>
                    <div>
                      {
                        DefectCodeScreenCode.find((e) => e.screenId === 116)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 229)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.defectCodeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 1}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 229)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(1)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={defectCodesObject.defectCode}
                          onChange={(e) => onChangeValue(e, "defectCode", null)}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 231)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.descriptionError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 2}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 231)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(2)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={defectCodesObject.description}
                          onChange={(e) =>
                            onChangeValue(e, "description", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 233)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.effDateFromError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 3}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 233)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(3)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              defectCodesObject.effDateFrom
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateFrom
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                defectCodesObject.effDateFrom
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "effDateFrom", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 235)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {defectCodesObjectErrors.effDateToError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 4}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 235)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(4)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              defectCodesObject.effDateTo
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  defectCodesObject.effDateTo
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(defectCodesObject.effDateTo).getUTCDate()
                            )
                          }
                          onChange={(e) => onChangeValue(e, "effDateTo", null)}
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>
                  <AddPageButton
                    close_modal={set_open_add_modal}
                    AddTheItem={AddTheItem}
                  />
              </div>
            ) : null}
          </div>
          </>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DefectCodes;
