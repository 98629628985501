import React, { useState, useEffect, useRef, useContext } from "react";
import ViewIcon from "../Assert/View.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_complaint } from "./Redux/ComplaintSlice";
import { populate_Products } from "./Redux/ProductsSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AddButton from "../Button/TableButton";
import EditPageButton from "../Button/EditPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import SearchIcon from "../Assert/Search.png";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";

function ComplaintMaster() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheScreensToolTips();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "complaintMaster?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  let product = useSelector((state) => state.callProducts.value);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  const [productArray, setProductArray] = useState([]);
  const FetchTheProduct = (_CompliaintMaster) => {
    if (product.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "productMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempProductMaster = [];
            let tempProductArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempProductMaster.push(response.data[i]);
                if (
                  tempProductArray.findIndex(
                    (e) => e === response.data[i].productName
                  ) == -1
                ) {
                  tempProductArray.push(response.data[i].productName);
                }
              }
            }
            dispatch(populate_Products(tempProductMaster));
            let tempProduct = [];
            for (let i = 0; i < tempProductMaster.length; i++) {
              if (
                _CompliaintMaster.findIndex(
                  (e) => e.productDbId == tempProductMaster[i].productDbId
                ) != -1
              ) {
                tempProduct.push(tempProductMaster[i]);
              }
            }
            setSelectedProducts(tempProduct);
            setProductArray(tempProduct);
          }
        });
    } else {
      // let tempProductMaster = [...product];
      // let tempProduct = [];
      // for (let i = 0; i < tempProductMaster.length; i++) {
      //   tempProduct.push(tempProductMaster[i].productName);
      // }
      let tempProduct = [];
      for (let i = 0; i < product.length; i++) {
        if (
          _CompliaintMaster.findIndex(
            (e) => e.productDbId == product[i].productDbId
          ) != -1
        ) {
          tempProduct.push(product[i]);
        }
      }
      setProductArray(tempProduct);
      setSelectedProducts(tempProduct);
    }
  };

  const [search_product, setsearch_product] = useState("");
  const [search_product_array, setsearch_product_array] = useState([]);
  const OnChangeSearchProduct = (e) => {
    let content = e.target.value;
    setsearch_product(content.toLowerCase());
    let temp = productArray.filter((e) =>
      e.productName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_product_array(temp);
  };

  let complaintMaster = useSelector((state) => state.callComplaint.value);
  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [complaintMasterObjectErrors, setComplaintMasterObjectErrors] =
    useState({
      customerCodeError: "",
      customerNameError: "",
      reportedByError: "",
      customerAddressError: "",
      customerContactNoError: "",
      customerEmailError: "",
      complaintDescError: "",
      complaintDateError: "",
      batchNoError: "",
      mfgMmYyyyError: "",
      noCansPailsError: "",
      productNameError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "customerCodeError");
    OnChangeErrorValues("", "customerNameError");
    OnChangeErrorValues("", "reportedByError");
    OnChangeErrorValues("", "customerAddressError");
    OnChangeErrorValues("", "customerContactNoError");
    OnChangeErrorValues("", "customerEmailError");
    OnChangeErrorValues("", "complaintDescError");
    OnChangeErrorValues("", "complaintDateError");
    OnChangeErrorValues("", "batchNoError");
    OnChangeErrorValues("", "mfgMmYyyyError");
    OnChangeErrorValues("", "noCansPailsError");
    OnChangeErrorValues("", "productNameError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "productNameError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          productNameError: error,
        }));
        break;
      case "customerCodeError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          customerCodeError: error,
        }));
        break;

      case "customerNameError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          customerNameError: error,
        }));
        break;
      case "reportedByError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          reportedByError: error,
        }));
        break;
      case "customerAddressError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          customerAddressError: error,
        }));
        break;
      case "customerContactNoError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          customerContactNoError: error,
        }));
        break;
      case "customerEmailError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          customerEmailError: error,
        }));
        break;
      case "complaintDescError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          complaintDescError: error,
        }));
        break;
      case "complaintDateError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          complaintDateError: error,
        }));
        break;
      case "batchNoError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          batchNoError: error,
        }));
        break;
      case "mfgMmYyyyError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          mfgMmYyyyError: error,
        }));
        break;
      case "noCansPailsError":
        setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
          ...complaintMasterObjectErrors,
          noCansPailsError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  // const onChangeSearchContent = (e) => {
  //   console.log(e.target.value);
  //   current_page.current = 1;
  //   let content = e.target.value;

  //   let temp = [];
  //   set_search_content(e.target.value.toLowerCase().trim());
  //   for (let i = 0; i < complaintMasterArray.length; i++) {
  //     if (
  //       complaintMasterArray[i].complaintMasterDetails.customerName !==
  //       null
  //     ) {
  //       if (
  //         complaintMasterArray[i].complaintMasterDetails.customerName
  //           .toLowerCase()
  //           .trim()
  //           .match(e.target.value.toLowerCase().trim())
  //       ) {
  //         temp.push(complaintMasterArray[i]);
  //       }
  //     }
  //   }
  //   set_searched_array(temp);
  //   console.log(search_content);
  // };

  const SearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = complaintMaster.filter((e1) =>
      e1.customerName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (complaintMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(complaintMaster[i]);
        }
      } else {
        for (let i = 0; i < complaintMaster.length; i++) {
          tempArray.push(complaintMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        complaintMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [complaintMasterArray, setComplaintMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  let product_array_ref = useRef([]);
  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [complaintMasterObject, setComplaintMasterObject] = useState({
    customerCode: "",
    customerName: "",
    reportedBy: "",
    customerAddress: "",
    customerContactNo: null,
    customerEmail: "",
    complaintDesc: "",
    complaintDate: new Date(),
    batchNo: "",
    mfgMmYyyy: new Date(),
    noCansPails: 0,
    productName: 0,
  });

  const onResetValue = () => {
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerCode: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerName: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      reportedBy: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerAddress: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerContactNo: 0,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerEmail: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      complaintDesc: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      complaintDate: new Date(),
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      batchNo: "",
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      mfgMmYyyy: new Date(),
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      noCansPails: 0,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      productName: 0,
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "customerCode":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          customerCode: e.target.value,
        }));
        break;
      case "customerName":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          customerName: e.target.value,
        }));
        break;
      case "reportedBy":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          reportedBy: e.target.value,
        }));
        break;
      case "customerAddress":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          customerAddress: e.target.value,
        }));
        break;
      case "customerContactNo":
        if(e.target.value.length<=11){
          setComplaintMasterObject((complaintMasterObject) => ({
            ...complaintMasterObject,
            customerContactNo: e.target.value,
          }));
        }
       
        break;
      case "customerEmail":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          customerEmail: e.target.value,
        }));
        break;
      case "complaintDesc":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          complaintDesc: e.target.value,
        }));
        break;
      case "complaintDate":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          complaintDate: e.target.value,
        }));
        break;
      case "batchNo":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          batchNo: e.target.value,
        }));
        break;
      case "mfgMmYyyy":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          mfgMmYyyy: e.target.value,
        }));
        break;
      case "noCansPails":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          noCansPails: e.target.value,
        }));
        break;

      case "productName":
        setComplaintMasterObject((complaintMasterObject) => ({
          ...complaintMasterObject,
          productName: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          complaintMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            complaintMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(complaintMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            complaintMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (complaintMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(complaintMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length === 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(complaintMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };

  const AddTheItem = () => {
    let data = {
      customerCode: complaintMasterObject.customerCode,
      customerName: complaintMasterObject.customerName,
      reportedBy: complaintMasterObject.reportedBy,
      customerAddress: complaintMasterObject.customerAddress,
      customerContactNo: Number(complaintMasterObject.customerContactNo),
      customerEmail: complaintMasterObject.customerEmail,
      complaintDesc: complaintMasterObject.complaintDesc,
      complaintDate: complaintMasterObject.complaintDate,
      batchNo: complaintMasterObject.batchNo,
      mfgMmYyyy: complaintMasterObject.mfgMmYyyy,
      noCansPails: Number(complaintMasterObject.noCansPails),
      productDbId: Number(complaintMasterObject.productName),
      createdBy: isAuthenticated == true ? user.name : null,
      createdTimestamp: new Date().toISOString(),
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(process.env.REACT_APP_BASE_API_PYTHON + "complaintMaster", data)
        .then((response) => {
          console.log("Status:" + response.status);
          console.log(response.data);
          if (response.status === 201) {
            // ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 337)
                  ?.shortMessage
              }`,
              "Success"
            );

            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON +
                "complaintMaster?size=1000",

              "Post"
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };

  const IsValidEmail = (_email) => {
    if (_email.length > 0) {
      if (
        _email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "ComplaintMaster") {
        csv_count++;

        switch (clientSideValidations[i].fieldName) {
          case "customerCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.customerCode.length === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
            
          case "customerName":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.customerName.length === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "reportedBy":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.reportedBy.length === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                reportedByError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "customerContactNo":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.customerContactNo.length == 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerContactNoError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              isNaN(complaintMasterObject.customerContactNo)
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerContactNoError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "customerEmail":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.customerEmail.length == 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "email" &&
              IsValidEmail(complaintMasterObject.customerEmail) === false
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerEmailError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "complaintDesc":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.complaintDesc.length === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                complaintDescError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "productDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.productName === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                productNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "complaintDate":
            if (
              clientSideValidations[i].validationType === "required" &&
              complaintMasterObject.complaintDate.length === 0
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                complaintDateError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "customer_contact_no":
            if (
              clientSideValidations[i].validationType === "max_length" &&
              complaintMasterObject.customerContactNo.length > 11
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerContactNoError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "numeric_int" &&
              !Number.isInteger(Number(complaintMasterObject.customerContactNo))
            ) {
              temp++;
              setComplaintMasterObjectErrors((complaintMasterObjectErrors) => ({
                ...complaintMasterObjectErrors,
                customerContactNoError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
 

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_complaintMaster = [];
    let temp_draft_array = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 336)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 389)
                    ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]) {
              temp_complaintMaster.push(response.data[i]);
              temp_count++;
            }
            if (response.data[i]) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_complaint(temp_complaintMaster));
          setComplaintMasterArray(temp_complaintMaster);
          //Get The Total Records
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            temp_complaintMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < temp_complaintMaster.length; i++) {
              temp_display_array.push(temp_complaintMaster[i]);
            }
          } else {
            if (
              temp_complaintMaster.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              temp_complaintMaster.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < temp_complaintMaster.length;
                i++
              ) {
                temp_display_array.push(temp_complaintMaster[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(temp_complaintMaster[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          FetchTheProduct(temp_complaintMaster);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        ShowMEWSPopup( error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
      });
  };
  const [selectedcomplaintDbId, setSelectedcomplaintDbId] = useState("");
  //View Modal||Edit Modal
  const OpenModal = (complaintDbId, type) => {
    setSelectedcomplaintDbId(complaintDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(complaintDbId);
  };
  const SupplyValuesToModal = (complaintDbId) => {
    let mfgMmYyyyDate = complaintMaster.find(
      (e) => e.complaintDbId == complaintDbId
    ).mfgMmYyyy;
    let complaintDate = complaintMaster.find(
      (e) => e.complaintDbId == complaintDbId
    ).complaintDate;
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerCode: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).customerCode,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerName: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).customerName,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      reportedBy: complaintMaster.find((e) => e.complaintDbId == complaintDbId)
        .reportedBy,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerAddress: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).customerAddress,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerContactNo: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).customerContactNo,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      customerEmail: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).customerEmail,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      complaintDesc: complaintMaster.find(
        (e) => e.complaintDbId == complaintDbId
      ).complaintDesc,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      batchNo: complaintMaster.find((e) => e.complaintDbId == complaintDbId)
        .batchNo,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      mfgMmYyyy: mfgMmYyyyDate,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      complaintDate: complaintDate,
    }));
    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      noCansPails: complaintMaster.find((e) => e.complaintDbId == complaintDbId)
        .noCansPails,
    }));

    setComplaintMasterObject((complaintMasterObject) => ({
      ...complaintMasterObject,
      productName: complaintMaster.find(
        (e1) => e1.complaintDbId == complaintDbId
      ).productDbId,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortFacilitie = [...complaintMasterArray];
    if (sortStyle === "Descending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res = action === "Code" ? a.customerCode : a.customerName;
        const b_res = action === "Code" ? b.customerCode : b.customerName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedProduction);
      setSortStyle("Ascending");
      setComplaintMasterArray(updatedProduction);
    } else if (sortStyle === "Ascending") {
      let updatedProduction = SortFacilitie.sort((a, b) => {
        const a_res = action === "Code" ? a.customerCode : a.customerName;
        const b_res = action === "Code" ? b.customerCode : b.customerName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedProduction);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortFacilitie.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    } else {
      for (let i = 0; i < SortFacilitie.length; i++) {
        tempArray.push(SortFacilitie[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortFacilitie.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setComplaintMasterArray(SortFacilitie);
    setPaginatedArray(tempArray);
  };
  const [productPopup, setProductPopup] = useState(false);
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target.id != "Product") {
        setProductPopup(false);
      }
    });
  }, []);
  const OnClickProduct = () => {
    // let temp = [];
    // if (selectedProducts.length == 0) {
    //   for (let i = 0; i < product.length; i++) {
    //     temp.push(product[i].productDbId);
    //   }
    // } else {
    //   for (let i = 0; i < selectedProducts.length; i++) {
    //     temp.push(selectedProducts[i]);
    //   }
    // }
    // setSelectedProducts(temp);
    if (complaintMaster.length == 0) {
      setProductPopup(false);
    } else {
      setProductPopup(!productPopup);
    }
  };
  const [selectedProducts, setSelectedProducts] = useState([]);

  const AddRemoveProduct = (id) => {
    let tempSelectedProduct = [...selectedProducts];
    let productIndex = tempSelectedProduct.findIndex(
      (e) => e.productDbId == id
    );
    if (productIndex == -1) {
      tempSelectedProduct.push(product.find((e) => e.productDbId == id));
    } else {
      tempSelectedProduct.splice(productIndex, 1);
    }
    setSelectedProducts(tempSelectedProduct);
  };
  const OnPressedClearClicked = (productDbId) => {
    let tempselectedProducts = [];
    if (selectedProducts.length == 0) {
      for (let i = 0; i < product.length; i++) {
        tempselectedProducts.push(product[i].productDbId);
      }
    } else {
      tempselectedProducts.splice(
        tempselectedProducts.findIndex((e) => e === productDbId),
        1
      );
      setSelectedProducts(tempselectedProducts);
    }
    setSelectedProducts(tempselectedProducts);
  };
  const OnOkProduct = () => {
    console.log(selectedProducts);
    let temp = [...complaintMaster];
    let updatedArray = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].productDbId == selectedProducts[i].productDbId
          // product.find((e) => e.productName == selectedProducts[i]).productDbId
        ) {
          updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setComplaintMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    setProductPopup(false);
  };
  const screenComplaintCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <div>
      {isAuthenticated == true ? (
        <div className="flex flex-col">
          <Header />

          <div className="container-fluid">
            <SuccessMessage
              mews_popup_color={mews_popup_color}
              open_edit_modal={open_edit_modal}
              show_mews_popup={show_mews_popup}
              multiple_codes={multiple_codes}
              mews_message={mews_message}
            />
            {open_add_modal == false &&
            open_edit_modal == false &&
            open_view_modal == false ? (
              <>
                <div className="grid lg:grid-cols-3 grid-cols-1 px-3 items-end justify-end gap-3 lg:py-5 py-3">
                  <div className="flex flex-col col-span-1 lg:items-start items-center">
                    <p></p>
                  </div>
                  <div className="flex flex-col col-span-1">
                    <div className="manageHeader">Manage Complaint</div>
                  </div>
                  <div className="flex lg:flex-row flex-col justify-end items-center col-span-1 lg:gap-7 gap-5">
                    <div className="searchInput">
                      <input
                        type="text"
                        placeholder="Search Complaint"
                        value={search_content}
                        onChange={(e) => SearchContent(e)}
                        className="border-0 px-1 outline-none rounded w-[96%]"
                      />
                      <img className="Icons " src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col
                          span="1"
                          style={{
                            width: "13%",
                          }}
                        />
                        <col
                          span="1"
                          style={{
                            width: "10%",
                          }}
                        />
                        <col
                          span="1"
                          style={{
                            width: "13%",
                          }}
                        />
                        <col
                          span="1"
                          style={{
                            width: "10%",
                          }}
                        />
                        <col span="1" style={{ width: "9%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "6%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "4%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <>
                                <img
                                  id="Product"
                                  className="Icons opacity-0"
                                  alt=""
                                />
                                Product
                                <img
                                  id="Product"
                                  className="Icons"
                                  onClick={() => OnClickProduct()}
                                  src={FilterIcon}
                                  alt=""
                                />
                                <label className="2xl:hidden block">
                                  {" "}
                                  &nbsp;
                                </label>
                              </>
                              {productPopup === true ? (
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Product"
                                          type="text"
                                          placeholder="Search"
                                          value={search_product}
                                          onChange={(e) =>
                                            OnChangeSearchProduct(e)
                                          }
                                        />
                                        <div
                                          id="Product"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedProducts(productArray)
                                          }
                                        >
                                          <img
                                            id="Product"
                                            className="Icons"
                                            src={
                                              productArray.length ==
                                              selectedProducts.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Product">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {search_product.length === 0
                                          ? productArray.map((item, index) => (
                                              <div
                                                id="Product"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveProduct(
                                                    item.productDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Product"
                                                  className="Icons"
                                                  src={
                                                    selectedProducts.findIndex(
                                                      (e) =>
                                                        e.productDbId ===
                                                        item.productDbId
                                                    ) !== -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Product">
                                                  {item.productName}
                                                </p>
                                              </div>
                                            ))
                                          : search_product.length > 0
                                          ? search_product_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Product"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveProduct(
                                                      item.productDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Product"
                                                    className="Icons"
                                                    src={
                                                      selectedProducts.findIndex(
                                                        (e) =>
                                                          e.productDbId ===
                                                          item.productDbId
                                                      ) !== -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Product">
                                                    {item.productName}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Product"
                                        className="popupfile_button"
                                        onClick={() => OnOkProduct()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Product"
                                        className="popupfile_button"
                                        onClick={() => OnPressedClearClicked()}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </th>
                          <th>Reported By</th>
                          <th>Address</th>
                          <th>Contact No</th>
                          <th>Email</th>
                          <th>Description</th>
                          <th>Batch No</th>
                          <th>Complaint Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) =>
                              item ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.complaintDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerName}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {product.length > 0 &&
                                    product.findIndex(
                                      (e) => e.productDbId === item.productDbId
                                    ) != -1
                                      ? product.find(
                                          (e) =>
                                            e.productDbId === item.productDbId
                                        ).productName
                                      : null}
                                  </td>
                                  <td
                                    className="text-left pl-3"
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.reportedBy}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerAddress}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerContactNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerEmail}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.complaintDesc.length > 10
                                      ? item.complaintDesc.substring(0, 10) +
                                        "..."
                                      : item.complaintDesc}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.batchNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.complaintDate).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.complaintDate
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.complaintDate
                                      ).getUTCFullYear()}
                                  </td>

                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.complaintDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : show_drafts === true
                          ? paginatedArray.map((item, index) =>
                              item ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.complaintDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerName}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {product.length > 0 &&
                                    product.findIndex(
                                      (e) => e.productDbId === item.productDbId
                                    ) != -1
                                      ? product.find(
                                          (e) =>
                                            e.productDbId === item.productDbId
                                        ).productName
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.reportedBy}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerAddress}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerContactNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerEmail}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.complaintDesc.length > 10
                                      ? item.complaintDesc.substring(0, 10) +
                                        "..."
                                      : item.complaintDesc}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.batchNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.complaintDate).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.complaintDate
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.complaintDate
                                      ).getUTCFullYear()}
                                  </td>

                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.complaintDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) =>
                              item ? (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.complaintDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerCode}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerName}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {product.length > 0 &&
                                    product.findIndex(
                                      (e) => e.productDbId === item.productDbId
                                    ) != -1
                                      ? product.find(
                                          (e) =>
                                            e.productDbId === item.productDbId
                                        ).productName
                                      : null}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.reportedBy}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerAddress}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerContactNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.customerEmail}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.complaintDesc.length > 10
                                      ? item.complaintDesc.substring(0, 10) +
                                        "..."
                                      : item.complaintDesc}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {item.batchNo}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.complaintDbId
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.complaintDbId
                                      )
                                    }
                                  >
                                    {new Date(item.complaintDate).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(
                                          item.complaintDate
                                        ).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(
                                        item.complaintDate
                                      ).getUTCFullYear()}
                                  </td>

                                  <td>
                                    <div className="anotherIconsSpace">
                                      <img
                                        className="Icons"
                                        onClick={() =>
                                          OpenModal(item.complaintDbId, "View")
                                        }
                                        src={ViewIcon}
                                        alt=""
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : null
                            )
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>

                  <AddButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      screenComplaintCode.find((e) => e.screenId === 143)
                        ?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>View Complaint Master Details</div>
                  <div>
                    {
                      screenComplaintCode.find((e) => e.screenId === 146)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Complaint Code</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.customerCode}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Complaint Name</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.customerName}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Product</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          product.findIndex(
                            (e) =>
                              e.productDbId ===
                              complaintMasterObject.productName
                          )!=-1? product.find(
                            (e) =>
                              e.productDbId ===
                              complaintMasterObject.productName
                          ).productName:null
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Reported By</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.reportedBy}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Customer Address</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.customerAddress}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">NoCans Pails</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.noCansPails}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Contact No</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.customerContactNo}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Customer Email</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.customerEmail}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Description</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={complaintMasterObject.complaintDesc}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Complaint Date</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            complaintMasterObject.complaintDate
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                complaintMasterObject.complaintDate
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              complaintMasterObject.complaintDate
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">MfgMmYyyy</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            complaintMasterObject.mfgMmYyyy
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                complaintMasterObject.mfgMmYyyy
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              complaintMasterObject.mfgMmYyyy
                            ).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between h-[12%] lg:px-7 md:px-5 px-4 gap-5">
                  <button
                    className="cancelButton"
                    onClick={() => set_open_view_modal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : null}

            {/* //Add Popup column */}
            {open_add_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Add Complaint Master Details</div>
                  <div>
                    {
                      screenComplaintCode.find((e) => e.screenId === 161)
                        ?.screenCode
                    }
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 381)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.customerCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 1}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 381)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(1)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.customerCode}
                        onChange={(e) => onChangeValue(e, "customerCode", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 382)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.customerNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 2}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 382)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(2)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.customerName}
                        onChange={(e) => onChangeValue(e, "customerName", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 383)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.productNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 3}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 383)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(3)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={complaintMasterObject.productName}
                        onChange={(e) => onChangeValue(e, "productName", null)}
                      >
                        <option value={null}>Select....</option>
                        {product.length > 0
                          ? product.map((item, index) => (
                              <option key={index} value={item.productDbId}>
                                {item.productName}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 384)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.reportedByError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 4}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 384)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(4)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.reportedBy}
                        onChange={(e) => onChangeValue(e, "reportedBy", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 385)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.customerAddressError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 5}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 385)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(5)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.customerAddress}
                        onChange={(e) =>
                          onChangeValue(e, "customerAddress", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 386)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.customerContactNoError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 6}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 386)
                            ?.toolTipDesc
                        }
                      />
                      <input maxLength={11}
                        onPointerEnter={() => setShowTooltip(6)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="number"
                        value={complaintMasterObject.customerContactNo}
                        onChange={(e) =>
                          onChangeValue(e, "customerContactNo", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 387)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.noCansPailsError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 7}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 387)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(7)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.noCansPails}
                        onChange={(e) => onChangeValue(e, "noCansPails", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 388)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.customerEmailError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 8}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 388)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(8)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.customerEmail}
                        onChange={(e) =>
                          onChangeValue(e, "customerEmail", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 389)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.complaintDescError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 9}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 389)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(9)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.complaintDesc}
                        onChange={(e) =>
                          onChangeValue(e, "complaintDesc", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 390)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.batchNoError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 10}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 390)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(10)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={complaintMasterObject.batchNo}
                        onChange={(e) => onChangeValue(e, "batchNo", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 391)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.complaintDateError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 11}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 391)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(11)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            complaintMasterObject.complaintDate
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                complaintMasterObject.complaintDate
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              complaintMasterObject.complaintDate
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) =>
                          onChangeValue(e, "complaintDate", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 392)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {complaintMasterObjectErrors.mfgMmYyyyError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 12}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 392)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(12)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            complaintMasterObject.mfgMmYyyy
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                complaintMasterObject.mfgMmYyyy
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(
                              complaintMasterObject.mfgMmYyyy
                            ).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "mfgMmYyyy", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <EditPageButton
                  close_modal={set_open_add_modal}
                  EditTheItem={AddTheItem}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </div>
  );
}

export default ComplaintMaster;
