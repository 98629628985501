import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_Equipment } from "./Redux/EquipmentSlice";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import ViewPageButton from "../Button/ViewPageButton";
import EditPageButton from "../Button/EditPageButton";
import AddPageButton from "../Button/AddPageButton";
import SuccessMessage from "../Constant/SuccessMessage";
import ManageSearch from "../Constant/ManageSearch";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function Equipment() {
  const [loading, setLoading] = useState(true);

  // const eventNotificationLog = useSelector(
  //   (state) => state.callEventNotificationLog.value
  // );
  // const FetchTheEventNotificationLog = () => {
  //   if (eventNotificationLog.length == 0) {
  //     axios
  //       .get(process.env.REACT_APP_BASE_API_PYTHON + "eventNotificationLog")
  //       .then((response) => {
  //         if (response.status == 200) {
  //           let temp = [];
  //           for (let i = 0; i < response.data.length; i++) {
  //             temp.push(response.data[i]);
  //           }
  //           dispatch(populate_event_notificationLog(temp));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };
  // let eventRules = useSelector((state) => state.callEventRules.value);

  // const FetchTheEventRule = () => {
  //   if (eventRules.length == 0) {
  //     axios
  //       .get(process.env.REACT_APP_BASE_API_PYTHON + "eventRules")
  //       .then((response) => {
  //         if (response.status == 200) {
  //           let temp = [];
  //           for (let i = 0; i < response.data.length; i++) {
  //             temp.push(response.data[i]);
  //           }
  //           dispatch(populate_Event_Rules(temp));
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // };
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheScreensToolTips();
        FetchTheData(
          process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",
          "Get"
        );
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  let EquipmentMaster = useSelector((state) => state.callEquipment.value);
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );
  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidationArray = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidationArray.push(response.data[i]);
            }
            dispatch(
              populate_client_side_validation(tempClientSideValidationArray)
            );
          }
        });
    }
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_SENSOR + "equipment";

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //Validations
  const [equipmentMasterObjectErrors, setEquipmentMasterObjectErrors] =
    useState({
      equipment_codeError: "",
      main_equipment_nameError: "",
      sub_assembly_nameError: "",
      sub_equipment_nameError: "",
      activeError: "",
      main_assembly_lineError: "",
      eff_date_fromError: "",
      eff_date_toError: "",
    });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "equipment_codeError");
    OnChangeErrorValues("", "main_equipment_nameError");
    OnChangeErrorValues("", "sub_assembly_nameError");
    OnChangeErrorValues("", "sub_equipment_nameError");
    OnChangeErrorValues("", "activeError");
    OnChangeErrorValues("", "eff_date_fromError");
    OnChangeErrorValues("", "eff_date_toError");
    OnChangeErrorValues("", "main_assembly_lineError");
  };
  const OnChangeErrorValues = (val, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "equipment_codeError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          equipment_codeError: val,
        }));
        break;
      case "main_equipment_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          main_equipment_nameError: val,
        }));
        break;
      case "sub_assembly_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          sub_assembly_nameError: val,
        }));
        break;
      case "sub_equipment_nameError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          sub_equipment_nameError: val,
        }));
        break;
      case "active":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          activeError: val,
        }));
        break;
      case "eff_date_fromError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          eff_date_fromError: val,
        }));
        break;
      case "eff_date_toError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          eff_date_toError: val,
        }));
        break;
      case "main_assembly_lineError":
        setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
          ...equipmentMasterObjectErrors,
          main_assembly_lineError: val,
        }));
    }
  };

  //Searching
  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);

  const onChangeSearchContent = (e) => {
    setLoading(true);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = EquipmentMaster.filter((e1) =>
      e1.main_equipment_name
        .toLowerCase()
        .trim()
        .match(content.toLowerCase().trim())
    );
    setLoading(false);
    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (EquipmentMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(EquipmentMaster[i]);
        }
      } else {
        for (let i = 0; i < EquipmentMaster.length; i++) {
          tempArray.push(EquipmentMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        EquipmentMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };
  ///////

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [equipmentMasterArray, setEquipmentMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [equipmentMasterObject, setEquipmentMasterObject] = useState({
    equipment_code: "",
    equipment_id: "",
    main_equipment_name: "",
    sub_assembly_name: "",
    sub_equipment_name: "",
    active: null,
    main_assembly_line: "",
    eff_date_from: new Date(),
    eff_date_to: new Date("9999-12-31"),
  });
  //Declaration Of States
  const [equipmentMasterObjectDelete, setEquipmentMasterObjectDelete] =
    useState({
      equipment_code: "",
      equipment_id: "",
      main_equipment_name: "",
      sub_assembly_name: "",
      sub_equipment_name: "",
      active: null,
      main_assembly_line: "",
      eff_date_from: new Date(),
      eff_date_to: new Date("9999-12-31"),
    });

  const onResetValue = () => {
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      equipment_code: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_equipment_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_assembly_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_equipment_name: "",
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      active: null,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_from: new Date(),
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_to: new Date("9999-12-31"),
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_assembly_line: "",
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "equipment_code":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          equipment_code: e.target.value,
        }));
        break;
      case "main_equipment_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          main_equipment_name: e.target.value,
        }));
        break;
      case "sub_assembly_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          sub_assembly_name: e.target.value,
        }));
        break;
      case "sub_equipment_name":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          sub_equipment_name: e.target.value,
        }));
        break;
      case "main_assembly_line":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          main_assembly_line: e.target.value,
        }));
        break;
      case "active":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          active: e.target.value,
        }));
        break;
      case "eff_date_from":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          eff_date_from: e.target.value,
        }));
        break;
      case "eff_date_to":
        setEquipmentMasterObject((equipmentMasterObject) => ({
          ...equipmentMasterObject,
          eff_date_to: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          equipmentMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            equipmentMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(equipmentMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            equipmentMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (equipmentMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(equipmentMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(equipmentMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };
  const return_mews_popup_color = (_color) => {
    return _color;
  };

  const [open_view_modal, set_open_view_modal] = useState(false);
  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);
  const [show_delete_message, set_show_delete_message] = useState("");
  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = EquipmentMaster.findIndex((e) => e.equipment_id === _code);
    select_deselect_the_record(EquipmentMaster[idx]);
    set_item_to_delete(EquipmentMaster[idx].equipment_id);
    set_show_delete_confirmations(true);
    set_show_delete_message("Are you sure you want to delete?");
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .delete(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          if (response.status === 200) {
            // ShowMEWSPopup("✅Successfully removed the record!!", "success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 274)
                  ?.shortMessage
              }`,
              "Success"
            );
            set_show_drafts(false)
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",
              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup( error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .delete(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              // ShowMEWSPopup("✅Successfully removed the record!!", "success");
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e) => e.validationId == 275)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_show_drafts(false)
              FetchTheData(
                process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup( error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    console.log(Number(equipmentMasterObject.active));

    let data = {
      active: Number(equipmentMasterObject.active),
      equipment_code: equipmentMasterObject.equipment_code,
      main_equipment_name: equipmentMasterObject.main_equipment_name,
      sub_equipment_name: equipmentMasterObject.sub_equipment_name,
      eff_date_from: equipmentMasterObject.eff_date_from,
      eff_date_to:
        status === true
          ? new Date("1900-01-01")
          : equipmentMasterObject.eff_date_to,
      main_assembly_line: equipmentMasterObject.main_assembly_line,
      created_by: isAuthenticated == true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated == true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          if (response.status === 201) {
            // ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 272)
                  ?.shortMessage
              }`,
              "Success"
            );

            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_SENSOR + "equipment?size=1000",
              "Post"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const UpdateTheItem = () => {
    let deleteData = {
      active: Number(equipmentMasterObject.active),
      equipment_code: equipmentMasterObjectDelete.equipment_code,
      main_equipment_name: equipmentMasterObjectDelete.main_equipment_name,
      sub_equipment_name: equipmentMasterObjectDelete.sub_equipment_name,
      eff_date_from: GetServerDate(equipmentMasterObjectDelete.eff_date_from),
      eff_date_to: OneBack(),
      main_assembly_line: equipmentMasterObjectDelete.main_assembly_line,
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    let data = {
      active: Number(equipmentMasterObject.active),
      equipment_code: equipmentMasterObject.equipment_code,
      main_equipment_name: equipmentMasterObject.main_equipment_name,
      sub_equipment_name: equipmentMasterObject.sub_equipment_name,
      eff_date_from: GetServerDate(equipmentMasterObject.eff_date_from),
      eff_date_to: GetServerDate(equipmentMasterObject.eff_date_to),
      main_assembly_line: equipmentMasterObject.main_assembly_line,
      created_by: isAuthenticated === true ? user.name : null,
      created_timestamp: new Date().toISOString(),
      updated_by: isAuthenticated === true ? user.name : null,
      updated_timestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .put(post_url + "/" + selectEquipment_id, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(post_url, data)
              .then((response1) => {
                console.log(response1);
                console.log(data);

                if (response1.status === 201) {
                  FetchTheData(
                    process.env.REACT_APP_BASE_API_SENSOR +
                      "equipment?size=1000",
                    "Patch"
                  );
                  // ShowMEWSPopup("✅Successfully updated the item", "Success");
                  ShowMEWSPopup(
                    `${
                      clientSideValidations.find((e) => e.validationId == 273)
                        ?.shortMessage
                    }`,
                    "Success"
                  );
                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if (multiple_codes.length > 0) {
                    set_multiple_codes([]);
                  }
                  set_show_drafts(false);
                }
              })
              .catch((error1) => {
                console.log(error1);
                ShowMEWSPopup( error1.message, "Error");
                set_mews_popup_color("#DC3545");
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          ShowMEWSPopup( error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Equipment") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "equipmentCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.equipment_code.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              new Date(equipmentMasterArray.findIndex(
                (e) => e.equipment_code === equipmentMasterObject.equipment_code
              ) != -1).getTime()===new Date("9999-12-31").getTime()||  new Date(equipmentMasterArray.findIndex(
                (e) => e.equipment_code === equipmentMasterObject.equipment_code
              ) != -1).getTime()===new Date("1900-01-01").getTime()
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              equipmentMasterArray.findIndex(
                (e) =>
                  e.main_equipment_name ===
                    equipmentMasterObject.main_equipment_name &&e.equipment_code ===
                    equipmentMasterObject.equipment_code &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.sub_assembly_name ===
                    equipmentMasterObject.sub_assembly_name
              ) != -1
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }
            break;

          case "mainAssemblyLine":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.main_assembly_line.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              EquipmentMaster.findIndex(
                (e) =>
                  e.main_equipment_name ==
                    equipmentMasterObject.main_equipment_name &&e.equipment_code ===
                    equipmentMasterObject.equipment_code &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.main_assembly_line ===
                    equipmentMasterObject.main_assembly_line
              ) != -1
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "mainEquipmentName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.main_equipment_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              EquipmentMaster.findIndex(
                (e) =>
                  e.main_equipment_name ==
                    equipmentMasterObject.main_equipment_name &&e.equipment_code ===
                    equipmentMasterObject.equipment_code &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.main_assembly_line ===
                    equipmentMasterObject.main_assembly_line
              ) != -1
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "subEquipmentName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.sub_equipment_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              EquipmentMaster.findIndex(
                (e) =>
                  e.main_equipment_name ==
                    equipmentMasterObject.main_equipment_name &&e.equipment_code ===
                    equipmentMasterObject.equipment_code &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.main_assembly_line ===
                    equipmentMasterObject.main_assembly_line
              ) != -1
            )  {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }

            break;
          case "subAssemblyName":
            if (
              clientSideValidations[i].validationType === "required" &&
              equipmentMasterObject.sub_assembly_name.length == 0
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_assembly_nameError: clientSideValidations[i].shortMessage,
              }));
            }
          
            break;
          case "equipmentCode,mainAssemblyLine,mainEquipmentName,subEquipmentName":
            if (
              clientSideValidations[i].validationType === "duplicatecombo" &&
              EquipmentMaster.findIndex(
                (e) =>
                  e.main_equipment_name ==
                    equipmentMasterObject.main_equipment_name &&e.equipment_code ===
                    equipmentMasterObject.equipment_code &&
                  e.sub_equipment_name ===
                    equipmentMasterObject.sub_equipment_name &&
                  e.main_assembly_line ===
                    equipmentMasterObject.main_assembly_line
              ) != -1
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                equipment_codeError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                sub_equipment_nameError: clientSideValidations[i].shortMessage,
              }));
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                main_assembly_lineError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(equipmentMasterObject.eff_date_from).getTime() >=
                new Date(equipmentMasterObject.eff_date_to).getTime()&& new Date(equipmentMasterObject.eff_date_to).getTime()!=new Date("1900-01-01").getTime()
            ) {
              temp++;
              setEquipmentMasterObjectErrors((equipmentMasterObjectErrors) => ({
                ...equipmentMasterObjectErrors,
                eff_date_fromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_show_delete_message(
        "Are you sure you want to delete " + multiple_codes.length + " records?"
      );
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          if (
            new Date(draft_array[i].eff_date_to).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = EquipmentMaster.length;
      //Get The Total Pages
      let temp=0
      for (let i = 0; i < EquipmentMaster.length; i++) {
      
       if (
         new Date(EquipmentMaster[i].eff_date_to).getTime() ===
         new Date("9999-12-31").getTime()
       ) {
         temp+=1
       }
     }
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (EquipmentMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < EquipmentMaster.length; i++) {
          temp_display_array.push(EquipmentMaster[i]);
          if (
            new Date(EquipmentMaster[i].eff_date_to).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
            temp_display_array.push(EquipmentMaster[i]);
          }
        }
      } else {
        if (
          EquipmentMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          EquipmentMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < EquipmentMaster.length;
            i++
          ) {
            if (
              new Date(EquipmentMaster[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(EquipmentMaster[i]);
            }
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            if (
              new Date(EquipmentMaster[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              temp_display_array.push(EquipmentMaster[i]);
            }
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_EquipmentMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 271)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 389)
                    ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.results.length; i++) {
            temp_EquipmentMaster.push(response.data.results[i]);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data.results[i]);
              temp_count++;
            }
            setEquipmentMasterArray(tempNonDraftArray);
            if (
              new Date(response.data.results[i].eff_date_to).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data.results[i]);
            }
          }
          set_draft_array(temp_draft_array);
          dispatch(populate_Equipment(temp_EquipmentMaster));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          setPaginatedArray(temp_display_array);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup( error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectEquipment_id, setSelectEquipment_id] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (equipment_id, type) => {
    setSelectEquipment_id(equipment_id);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(equipment_id);
  };
  const SupplyValuesToModal = (equipment_id) => {
    let dateFrom = EquipmentMaster.find(
      (e) => e.equipment_id == equipment_id
    ).eff_date_from;
    let dateTo = EquipmentMaster.find(
      (e) => e.equipment_id == equipment_id
    ).eff_date_to;
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      equipment_code: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).equipment_code,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_equipment_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_assembly_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_assembly_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      sub_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_equipment_name,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      active: EquipmentMaster.find((e) => e.equipment_id == equipment_id)
        .active,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      main_assembly_line: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_assembly_line,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_from: dateFrom,
    }));
    setEquipmentMasterObject((equipmentMasterObject) => ({
      ...equipmentMasterObject,
      eff_date_to: dateTo,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      equipment_code: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).equipment_code,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      main_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_equipment_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      sub_assembly_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_assembly_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      sub_equipment_name: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).sub_equipment_name,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      active: EquipmentMaster.find((e) => e.equipment_id == equipment_id)
        .active,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      main_assembly_line: EquipmentMaster.find(
        (e) => e.equipment_id == equipment_id
      ).main_assembly_line,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      eff_date_from: dateFrom,
    }));
    setEquipmentMasterObjectDelete((equipmentMasterObjectDelete) => ({
      ...equipmentMasterObjectDelete,
      eff_date_to: dateTo,
    }));
  };
  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortingEquipment = [...equipmentMasterArray];
    if (sortStyle === "Descending") {
      let UpdatedEquipment = SortingEquipment.sort((a, b) => {
        const a_res =
          action === "Code" ? a.equipment_code : a.main_equipment_name;
        const b_res =
          action === "Code" ? b.equipment_code : b.main_equipment_name;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedEquipment);
      setSortStyle("Ascending");
    }
    if (sortStyle === "Ascending") {
      let UpdatedEquipment = SortingEquipment.sort((a, b) => {
        const a_res =
          action === "Code" ? a.equipment_code : a.main_equipment_name;
        const b_res =
          action === "Code" ? b.equipment_code : b.main_equipment_name;

        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(UpdatedEquipment);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortingEquipment.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortingEquipment[i]);
      }
    } else {
      for (let i = 0; i < SortingEquipment.length; i++) {
        tempArray.push(SortingEquipment[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortingEquipment.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPaginatedArray(tempArray);
    setEquipmentMasterArray(SortingEquipment);
  };
  const EquipmentScreenCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <div>
      <div className="flex flex-col">
        {isAuthenticated == true ? (
          <>
            <Header />
            <div className="container-fluid">
              <SuccessMessage
                mews_popup_color={mews_popup_color}
                open_edit_modal={open_edit_modal}
                show_mews_popup={show_mews_popup}
                multiple_codes={multiple_codes}
                mews_message={mews_message}
              />
              {open_add_modal == false &&
              open_edit_modal == false &&
              open_view_modal == false ? (
                <>
                  <ManageSearch
                    name="Equipment"
                    OnClickShowDrafts={OnClickShowDrafts}
                    show_drafts={show_drafts}
                    toolTipName={
                      screensToolTips.find((e) => e.toolTipDbId == 421)
                        ?.toolTipDesc
                    }
                    search_content={search_content}
                    onChangeSearchContent={onChangeSearchContent}
                    placeholder="Equipment"
                  />

                  <div className="tablerounds">
                    <div className="tableboxes">
                      <table>
                        <colgroup>
                          <col span="1" style={{ width: "4%" }} />
                          <col span="1" style={{ width: "18%" }} />
                          <col span="1" style={{ width: "18%" }} />
                          <col span="1" style={{ width: "18%" }} />
                          <col span="1" style={{ width: "18%" }} />
                          <col span="1" style={{ width: "8%" }} />
                          <col span="1" style={{ width: "8%" }} />
                          <col span="1" style={{ width: "8%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Sl.</th>

                            <th>
                              <div className="IconsSpace">
                                <img
                                  onClick={() =>
                                    AscendingDescendingFunction("Code")
                                  }
                                  className="ArrowIcons"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                                Equipment Code
                                <img
                                  className="ArrowIcons opacity-0"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                              </div>
                            </th>
                            <th>
                              <div className="IconsSpace">
                                <img
                                  onClick={() =>
                                    AscendingDescendingFunction("Code")
                                  }
                                  className="ArrowIcons"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                                Main Equipment Name
                                <img
                                  className="ArrowIcons opacity-0"
                                  src={
                                    sortStyle === "Descending"
                                      ? ArrowDown
                                      : ArrowUp
                                  }
                                  alt=""
                                />
                              </div>
                            </th>
                            <th>Main Assembly Line</th>
                            <th>Sub Equipment Name</th>
                            {/* <th>Active</th> */}
                            <th>Effective From</th>
                            <th>Effective To</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {show_drafts === false && search_content.length === 0
                            ? paginatedArray.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.equipment_code}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_equipment_name}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_assembly_line}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.sub_equipment_name}
                                  </td>
                                  {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === 1 ? "Yes" : "No"}
                          </td> */}

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.eff_date_from).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.eff_date_from
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.eff_date_from
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {new Date(item.eff_date_to).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.eff_date_to).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(
                                        item.eff_date_to
                                      ).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.equipment_id}
                                    />
                                  </td>
                                </tr>
                              ))
                            : show_drafts === true
                            ? display_draft_array.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {index +
                                      1 +
                                      process.env.REACT_APP_ITEMS_PER_PAGE *
                                        (current_page.current - 1)}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.equipment_code}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_equipment_name}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_assembly_line}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.sub_equipment_name}
                                  </td>

                                  {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === true
                              ? "Yes"
                              : item.active === false
                              ? "No"
                              : null}
                          </td> */}

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.eff_date_from).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.eff_date_from
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.eff_date_from
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {new Date(item.eff_date_to).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.eff_date_to).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(
                                        item.eff_date_to
                                      ).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.equipment_id}
                                    />
                                  </td>
                                </tr>
                              ))
                            : search_content.length > 0
                            ? paginatedArray.map((item, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.equipment_id
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  key={index}
                                >
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.equipment_code}
                                  </td>

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_equipment_name}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.main_assembly_line}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {item.sub_equipment_name}
                                  </td>

                                  {/* <td
                            style={{
                              borderColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#FFFFFF"
                                  : "#DAE0E4",
                              backgroundColor:
                                multiple_codes.findIndex(
                                  (e) => e === item.equipment_id
                                ) !== -1
                                  ? "#DAE0E4"
                                  : "#FFFFFF",
                            }}
                            onClick={() =>
                              select_deselect_the_record(item.equipment_id)
                            }
                          >
                            {item.active === true
                              ? "Yes"
                              : item.active === false
                              ? "No"
                              : null}
                          </td> */}

                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {ReturnThePaddedValues(
                                      new Date(item.eff_date_from).getUTCDate()
                                    ) +
                                      "-" +
                                      ReturnThePaddedValues(
                                        Number(
                                          new Date(
                                            item.eff_date_from
                                          ).getUTCMonth()
                                        ) + 1
                                      ) +
                                      "-" +
                                      new Date(
                                        item.eff_date_from
                                      ).getUTCFullYear()}
                                  </td>
                                  <td
                                    style={{
                                      borderColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#FFFFFF"
                                          : "#DAE0E4",
                                      backgroundColor:
                                        multiple_codes.findIndex(
                                          (e) => e === item.equipment_id
                                        ) !== -1
                                          ? "#DAE0E4"
                                          : "#FFFFFF",
                                    }}
                                    onClick={() =>
                                      select_deselect_the_record(
                                        item.equipment_id
                                      )
                                    }
                                  >
                                    {new Date(item.eff_date_to).getUTCDate() +
                                      "-" +
                                      (Number(
                                        new Date(item.eff_date_to).getUTCMonth()
                                      ) +
                                        1) +
                                      "-" +
                                      new Date(
                                        item.eff_date_to
                                      ).getUTCFullYear()}
                                  </td>
                                  <td>
                                    <IconComponent
                                      multiple_codes={multiple_codes}
                                      OpenModal={OpenModal}
                                      ShowDeleteConfirmation={
                                        ShowDeleteConfirmation
                                      }
                                      selectedFormDbId={item.equipment_id}
                                    />
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                      <div className="flex flex-col items-center justify-center">
                        <p>
                          {" "}
                          {paginatedArray.length == 0 ? "No records found" : ""}
                        </p>
                      </div>
                      <div />
                    </div>
                    <TableButton
                      current_page={current_page.current}
                      total_pages={total_pages.current}
                      onPressPrevious={onPressPrevious}
                      onPressNext={onPressNext}
                      array={paginatedArray}
                      screenCode={
                        EquipmentScreenCode.find((e) => e.screenId === 68)
                          ?.screenCode
                      }
                      onClickAddItem={onClickAddItem}
                      OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                      multiple_codes_delete={multiple_codes.length > 1}
                    />
                  </div>
                </>
              ) : null}
              {/* //View box */}
              {open_view_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>View Equipment Details</div>
                    <div>
                      {
                        EquipmentScreenCode.find((e) => e.screenId === 68)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Equipment Code</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={equipmentMasterObject.equipment_code}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Equipment Name</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={equipmentMasterObject.sub_equipment_name}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Main Assembly Line
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={equipmentMasterObject.main_assembly_line}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Active</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            equipmentMasterObject.active === 1
                              ? "True"
                              : "False"
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            Sub Equipment Name
                          </label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={equipmentMasterObject.sub_equipment_name}
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date From</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">Eff. Date To</label>
                        </div>
                        <input
                          type="text"
                          disabled={true}
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          className="child_input"
                        />
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <ViewPageButton
                    close_modal={set_open_view_modal}
                    OpenModal={OpenModal}
                    selectedDbId={selectEquipment_id}
                  />
                </div>
              ) : null}
              {/* //Edit Popup column */}
              {open_edit_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Edit Equipment Details</div>
                    <div>
                      {
                        EquipmentScreenCode.find((e) => e.screenId === 71)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 58)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.equipment_codeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 8}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 58)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(8)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.equipment_code}
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 57)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.main_equipment_nameError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 9}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 57)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(9)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.main_equipment_name}
                          onChange={(e) =>
                            onChangeValue(e, "main_equipment_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 60)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.main_assembly_lineError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 10}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 60)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(10)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.main_assembly_line}
                          onChange={(e) =>
                            onChangeValue(e, "main_assembly_line", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 62)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.activeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 11}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 62)
                              ?.toolTipDesc
                          }
                        />
                        <select
                          onPointerEnter={() => setShowTooltip(11)}
                          onPointerLeave={() => setShowTooltip(0)}
                          className="child_input"
                          value={equipmentMasterObject.active}
                          onChange={(e) => onChangeValue(e, "active", null)}
                        >
                          <option value={null}>Select Active</option>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 64)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.sub_equipment_nameError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 12}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 64)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(12)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.sub_equipment_name}
                          onChange={(e) =>
                            onChangeValue(e, "sub_equipment_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 66)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 13}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 66)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(13)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 68)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 14}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 68)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(14)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>

                  <EditPageButton
                    close_modal={set_open_edit_modal}
                    EditTheItem={UpdateTheItem}
                  />
                </div>
              ) : null}
              {/* //Delete Popup column */}
              {show_delete_confirmations ? (
                <DeleteConfirmationModal
                  CancelTheDeletion={CancelTheDeletion}
                  OnDeleteItem={OnDeleteItem}
                  item_to_delete={item_to_delete}
                />
              ) : null}

              {/* //Add Popup column */}
              {open_add_modal ? (
                <div className="all_popup_box">
                  <div className="green_header_popup">
                    <div>Add Equipment Details</div>
                    <div>
                      {
                        EquipmentScreenCode.find((e) => e.screenId === 69)
                          ?.screenCode
                      }
                    </div>
                  </div>
                  <div className="inputOverflow">
                    <div className="inputGrid">
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 53)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.equipment_codeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 1}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 53)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(1)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.equipment_code}
                          onChange={(e) =>
                            onChangeValue(e, "equipment_code", null)
                          }
                          className="child_input"
                        />
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 55)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.main_equipment_nameError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 2}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 55)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(2)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.main_equipment_name}
                          onChange={(e) =>
                            onChangeValue(e, "main_equipment_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup ">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 59)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.main_assembly_lineError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 3}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 59)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(3)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.main_assembly_line}
                          onChange={(e) =>
                            onChangeValue(e, "main_assembly_line", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 61)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.activeError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 4}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 61)
                              ?.toolTipDesc
                          }
                        />
                        <select
                          onPointerEnter={() => setShowTooltip(4)}
                          onPointerLeave={() => setShowTooltip(0)}
                          className="child_input"
                          value={equipmentMasterObject.active}
                          onChange={(e) => onChangeValue(e, "active", null)}
                        >
                          <option value={null}>Select...</option>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>

                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 63)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {
                              equipmentMasterObjectErrors.sub_equipment_nameError
                            }
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 5}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 63)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(5)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="text"
                          value={equipmentMasterObject.sub_equipment_name}
                          onChange={(e) =>
                            onChangeValue(e, "sub_equipment_name", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 65)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.eff_date_fromError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 6}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 65)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(6)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_from
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_from
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_from
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_from", null)
                          }
                          className="child_input"
                        />
                      </div>
                      <div className="parent_popup">
                        <div className="child_label_flex">
                          <label className="child_popup">
                            {
                              screensToolTips.find((e) => e.toolTipDbId == 67)
                                ?.labelName
                            }
                          </label>
                          <label className="error">
                            {equipmentMasterObjectErrors.eff_date_toError}
                          </label>
                        </div>
                        <InputToolTip
                          showTooltip={showTooltip == 7}
                          name={
                            screensToolTips.find((e) => e.toolTipDbId == 67)
                              ?.toolTipDesc
                          }
                        />
                        <input
                          onPointerEnter={() => setShowTooltip(7)}
                          onPointerLeave={() => setShowTooltip(0)}
                          type="date"
                          value={
                            new Date(
                              equipmentMasterObject.eff_date_to
                            ).getUTCFullYear() +
                            "-" +
                            ReturnThePaddedValues(
                              Number(
                                new Date(
                                  equipmentMasterObject.eff_date_to
                                ).getUTCMonth()
                              ) + 1
                            ) +
                            "-" +
                            ReturnThePaddedValues(
                              new Date(
                                equipmentMasterObject.eff_date_to
                              ).getUTCDate()
                            )
                          }
                          onChange={(e) =>
                            onChangeValue(e, "eff_date_to", null)
                          }
                          className="child_input"
                        />
                      </div>
                    </div>
                  </div>

                  <AddPageButton
                    close_modal={set_open_add_modal}
                    AddTheItem={AddTheItem}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {loading == true ? (
          <div className="LoadingOpectity">
            <img className="LoadingGif" src={LoadingIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Equipment;
