import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { populate_supplier } from "./Redux/SupplierSlice";
import { populate_parts } from "./Redux/PartsSlice";
import Header from "./Header";
import LoadingIcon from "../Assert/Loading.gif";
import ArrowUp from "../Assert/ArrowUp.png";
import ArrowDown from "../Assert/ArrowDown.png";
import FilterIcon from "../Assert/Filter.png";
import CheckIcon from "../Assert/CheckIcon.png";
import UnCheckIcon from "../Assert/UnCheckIcon.png";
import { populate_client_side_validation } from "./Redux/ClientSideValidationSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import TableButton from "../Button/TableButton";
import DeleteConfirmationModal from "../DeleteComponent/DeleteConfirmationModal";
import ViewPageButton from "../Button/ViewPageButton";
import EditPageButton from "../Button/EditPageButton";
import AddPageButton from "../Button/AddPageButton";
import ManageSearch from "../Constant/ManageSearch";
import SuccessMessage from "../Constant/SuccessMessage";
import { ScreenCodeContext } from "../App";
import { populate_ScreenToolTips } from "./Redux/ScreenToolTipsSlice";
import InputToolTip from "../Constant/InputToolTip";
import IconComponent from "../Constant/IconComponent";

function Parts() {
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated === false) {
        navigate("/");
      } else {
        FetchTheValidation();
        FetchTheData(
          process.env.REACT_APP_BASE_API_PYTHON + "partsMaster?size=1000",
          "Get"
        );
        FetchTheScreensToolTips();
        window.addEventListener("click", (e) => {
          if (e.target.id != "Supplier") {
            setSupplierPop(false);
          }
        });
      }
    }, 2000);
  }, []);
  const screensToolTips = useSelector(
    (state) => state.callScreenToolTips.value
  );
  const FetchTheScreensToolTips = () => {
    if (screensToolTips.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON + "screensToolTips?size=1000"
        )
        .then((response) => {
          if (response.status == 200) {
            let tempScreensToolTips = [];
            for (let i = 0; i < response.data.length; i++) {
              tempScreensToolTips.push(response.data[i]);
            }
            dispatch(populate_ScreenToolTips(tempScreensToolTips));
          }
        });
    }
  };
  //Get all validation
  const clientSideValidations = useSelector(
    (state) => state.callClientSideValidation.value
  );

  const FetchTheValidation = () => {
    if (clientSideValidations.length == 0) {
      axios
        .get(
          process.env.REACT_APP_BASE_API_PYTHON +
            "clientSideValidations?size=1000"
        )
        .then((response) => {
          if (response.status === 200) {
            let tempClientSideValidation = [];
            for (let i = 0; i < response.data.length; i++) {
              tempClientSideValidation.push(response.data[i]);
            }
            dispatch(populate_client_side_validation(tempClientSideValidation));
          }
        });
    }
  };
  let supplierMaster = useSelector((state) => state.callSupplier.value);
  const [supplierArray, setSupplierArray] = useState([]);
  const FetchTheSupplier = (_partsMaster) => {
    if (supplierMaster.length === 0) {
      axios
        .get(process.env.REACT_APP_BASE_API_PYTHON + "supplierMaster")
        .then((response) => {
          if (response.status === 200) {
            let tempSupplierMaster = [];
            let tempSupplierArray = [];
            for (let i = 0; i < response.data.length; i++) {
              if (
                new Date(response.data[i].effDateTo).getTime() ===
                new Date("9999-12-31").getTime()
              ) {
                tempSupplierMaster.push(response.data[i]);
                if (
                  tempSupplierArray.findIndex(
                    (e) => e === response.data[i].supplierName
                  ) == -1
                ) {
                  tempSupplierArray.push(response.data[i].supplierName);
                }
              }
            }
            dispatch(populate_supplier(tempSupplierMaster));
            let tempSuppliers = [];
            for (let i = 0; i < tempSupplierMaster.length; i++) {
              if (
                _partsMaster.findIndex(
                  (e) => e.supplierDbId == tempSupplierMaster[i].supplierDbId
                ) != -1
              ) {
                tempSuppliers.push(tempSupplierMaster[i]);
              }
            }
            setSelectedSupplier(tempSuppliers);
            setSupplierArray(tempSuppliers);
          }
        });
    } else {
      // let tempSupplierMaster = [...supplierMaster];
      // let tempSupplier = [];
      // for (let i = 0; i < tempSupplierMaster.length; i++) {
      //   tempSupplier.push(tempSupplierMaster[i].supplierName);
      // }
      // setSelectedSupplier(tempSupplier);
      // setSupplierArray(tempSupplier);
      let tempSuppliers = [];
      for (let i = 0; i < supplierMaster.length; i++) {
        if (
          _partsMaster.findIndex(
            (e) => e.supplierDbId == supplierMaster[i].supplierDbId
          ) != -1
        ) {
          tempSuppliers.push(supplierMaster[i]);
        }
      }
      setSelectedSupplier(tempSuppliers);
      setSupplierArray(tempSuppliers);
    }
  };
  const [search_supplier, setsearch_supplier] = useState("");
  const [search_supplier_array, setsearch_supplier_array] = useState([]);
  const OnChangeSearchSupplier = (e) => {
    let content = e.target.value;
    setsearch_supplier(content.toLowerCase());
    let temp = supplierArray.filter((e) =>
      e.supplierName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setsearch_supplier_array(temp);
  };
  //Post Url
  const post_url = process.env.REACT_APP_BASE_API_PYTHON + "partsMaster";

  let partsMaster = useSelector((state) => state.callParts.value);

  //Padded Values
  const ReturnThePaddedValues = (_num) => {
    return _num.length == 2 ? _num : String(_num).padStart(2, 0);
  };
  //////////////
  //Validations
  const [partsMasterObjectErrors, setPartsMasterObjectErrors] = useState({
    partCodeError: "",
    partNameError: "",
    partUomError: "",
    supplierNameError: "",
    partPurchasePriceError: "",
    partDolFlagError: "",
    effDateFromError: "",
    effDateToError: "",
  });
  const OnResetErrors = () => {
    OnChangeErrorValues("", "partCodeError");
    OnChangeErrorValues("", "partNameError");
    OnChangeErrorValues("", "partUomError");
    OnChangeErrorValues("", "supplierNameError");
    OnChangeErrorValues("", "partPurchasePriceError");
    OnChangeErrorValues("", "partDolFlagError");
    OnChangeErrorValues("", "effDateFromError");
    OnChangeErrorValues("", "effDateToError");
  };
  const OnChangeErrorValues = (error, type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "partCodeError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          partCodeError: error,
        }));
        break;

      case "partNameError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          partNameError: error,
        }));
        break;
      case "partUomError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          partUomError: error,
        }));
        break;
      case "supplierNameError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          supplierNameError: error,
        }));
        break;
      case "partPurchasePriceError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          partPurchasePriceError: error,
        }));
        break;
      case "partDolFlagError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          partDolFlagError: error,
        }));
        break;
      case "effDateFromError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          effDateFromError: error,
        }));
        break;
      case "effDateToError":
        setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
          ...partsMasterObjectErrors,
          effDateToError: error,
        }));
        break;
    }
  };

  //Searching

  const [search_content, set_search_content] = useState("");
  const [searched_array, set_searched_array] = useState([]);
  const [draft_array, set_draft_array] = useState([]);
  const [display_draft_array, set_display_draft_array] = useState([]);
  const onChangeSearchContent = (e) => {
    setLoading(true);
    console.log(e.target.value);
    current_page.current = 1;
    let content = e.target.value;
    set_search_content(content);
    let temp = partsMaster.filter((e1) =>
      e1.partName.toLowerCase().trim().match(content.toLowerCase().trim())
    );
    setLoading(false);

    let tempArray = [];
    if (temp.length == 0 && e.target.value.length == 0) {
      if (partsMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(partsMaster[i]);
        }
      } else {
        for (let i = 0; i < partsMaster.length; i++) {
          tempArray.push(partsMaster[i]);
        }
      }
      total_pages.current = Math.ceil(
        partsMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    } else {
      if (temp.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
        for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
          tempArray.push(temp[i]);
        }
      } else {
        for (let i = 0; i < temp.length; i++) {
          tempArray.push(temp[i]);
        }
      }
      total_pages.current = Math.ceil(
        temp.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
    }
    current_page.current = 1;
    setPaginatedArray(tempArray);
    set_searched_array(temp);
  };

  const total_records = useRef(0);
  const total_pages = useRef(0);
  const current_page = useRef(1);
  const [partsMasterArray, setPartsMasterArray] = useState([]);
  const [show_drafts, set_show_drafts] = useState(false);

  //Multi Select Option
  const [multiple_codes, set_multiple_codes] = useState([]);
  const select_deselect_the_record = (_code) => {
    let temp_multiple_codes = [];
    for (let i = 0; i < multiple_codes.length; i++) {
      temp_multiple_codes.push(multiple_codes[i]);
    }
    if (temp_multiple_codes.findIndex((e) => e === _code) === -1) {
      temp_multiple_codes.push(_code);
      set_multiple_codes(temp_multiple_codes);
    } else {
      temp_multiple_codes.splice(
        temp_multiple_codes.findIndex((e) => e === _code),
        1
      );
      set_multiple_codes(temp_multiple_codes);
    }
    if (temp_multiple_codes.length > 0) {
      set_mews_popup_color("#28A745");
    }
  };
  //
  const dispatch = useDispatch();
  const [open_edit_modal, set_open_edit_modal] = useState(false);

  //Declaration Of States
  const [partsMasterObject, setPartsMasterObject] = useState({
    partCode: "",
    partName: "",
    partUom: "",
    supplierName: "",
    partPurchasePrice: "",
    partDolFlag: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
  });

  const [partsMasterObjectDelete, setPartsMasterObjectDelete] = useState({
    partCode: "",
    partName: "",
    partUom: "",
    supplierName: "",
    partPurchasePrice: "",
    partDolFlag: "",
    effDateFrom: new Date(),
    effDateTo: new Date("9999-12-31"),
    createdBy: "",
    updatedBy: "",
    createdTimestamp: new Date(),
    updatedTimestamp: new Date(),
  });
  const onResetValue = () => {
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partCode: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partName: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partUom: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      supplierName: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partPurchasePrice: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partDolFlag: "",
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      effDateFrom: new Date(),
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      effDateTo: new Date("9999-12-31"),
    }));
  };
  const onChangeValue = (e, type, value) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "partCode":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          partCode: e.target.value,
        }));
        break;
      case "partName":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          partName: e.target.value,
        }));
        break;
      case "partUom":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          partUom: e.target.value,
        }));
        break;
      case "supplierName":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          supplierName: e.target.value,
        }));
        break;
      case "partPurchasePrice":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          partPurchasePrice: e.target.value,
        }));
        break;
      case "partDolFlag":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          partDolFlag: e.target.value,
        }));
        break;
      case "effDateFrom":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          effDateFrom: e.target.value,
        }));
        break;
      case "effDateTo":
        setPartsMasterObject((partsMasterObject) => ({
          ...partsMasterObject,
          effDateTo: e.target.value,
        }));
        break;
    }
  };
  const [paginatedArray, setPaginatedArray] = useState([]);

  const onPressNext = () => {
    let temp = [];
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          partsMasterArray.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            partsMasterArray.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(partsMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            partsMasterArray.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (partsMasterArray.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(partsMasterArray[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          searched_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            searched_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            searched_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (searched_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(searched_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    } else if (show_drafts === true) {
      if (current_page.current + 1 <= total_pages.current) {
        if (
          draft_array.length >
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          if (
            draft_array.length >=
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          } else if (
            draft_array.length <
            (current_page.current + 1) * process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            for (
              let i =
                current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
              i <
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE +
                (draft_array.length -
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE);
              i++
            ) {
              temp.push(draft_array[i]);
            }
            current_page.current += 1;
            setPaginatedArray(temp);
          }
        }
      }
    }
  };
  const onPressPrevious = () => {
    if (show_drafts === false && search_content.length == 0) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(partsMasterArray[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (search_content.length > 0 && show_drafts === false) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(searched_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    } else if (show_drafts === true) {
      if (current_page.current - 1 > 0) {
        let temp = [];
        for (
          let i =
            (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE;
          i < (current_page.current - 1) * process.env.REACT_APP_ITEMS_PER_PAGE;
          i++
        ) {
          temp.push(draft_array[i]);
        }
        current_page.current -= 1;
        setPaginatedArray(temp);
      }
    }
  };
  //Error/Warning/Success Popup Message
  const [mews_message, set_mews_message] = useState("");
  const [show_mews_popup, set_show_mews_popup] = useState(false);
  const [mews_popup_color, set_mews_popup_color] = useState("");
  const ShowMEWSPopup = (_message, _type) => {
    set_mews_message(_message);
    set_show_mews_popup(true);
    setTimeout(() => {
      set_show_mews_popup(false);
    }, 5000);
  };

  //To View The Item
  const [open_view_modal, set_open_view_modal] = useState(false);

  //To Add The Item
  const [open_add_modal, set_open_add_modal] = useState(false);
  const onClickAddItem = () => {
    set_open_add_modal(true);
    set_open_edit_modal(false);
    set_open_view_modal(false);
    onResetValue();
    OnResetErrors();
  };
  //Show Deletion Confirmations
  const [show_delete_confirmations, set_show_delete_confirmations] =
    useState(false);

  const [delete_multiple, set_delete_multiple] = useState(false);
  const [item_to_delete, set_item_to_delete] = useState("");
  const ShowDeleteConfirmation = (_code) => {
    let idx = partsMaster.findIndex((e) => e.partDbId === _code);
    select_deselect_the_record(partsMaster[idx]);
    set_item_to_delete(partsMaster[idx].partDbId);
    set_show_delete_confirmations(true);
  };
  const CancelTheDeletion = () => {
    set_show_delete_confirmations(false);
    set_multiple_codes([]);
    if (delete_multiple === true) {
      set_delete_multiple(false);
    }
  };

  const OnDeleteItem = (_code) => {
  
    set_show_delete_confirmations(false);
    if (delete_multiple === false) {
      axios
        .patch(post_url + "/" + item_to_delete, {
          updatedTimestamp: new Date().toISOString(),
          effDateTo: OneBack(),
          updatedBy: isAuthenticated == true ? user.name : "",
        })
        .then((response) => {
          console.log("Delete:" + response.status);
          if (response.status === 200) {
            // ShowMEWSPopup("✅Successfully removed the record!!", "success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 259)
                  ?.shortMessage
              }`,
              "Success"
            );
             set_show_drafts(false)
            set_multiple_codes([]);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "partsMaster?size=1000",

              "Delete"
            );
          } else {
            ShowMEWSPopup(response.message, "warning");
          }
        })
        .catch((error) => {
          ShowMEWSPopup( error.message, "error");
        });
    } else {
      for (let i = 0; i < multiple_codes.length; i++) {
        axios
          .patch(post_url + "/" + multiple_codes[i], {
            updatedTimestamp: new Date().toISOString(),
            effDateTo: OneBack(),
            updatedBy: isAuthenticated == true ? user.name : "",
          })
          .then((response) => {
            if (response.status === 200) {
              // ShowMEWSPopup("✅Successfully removed the record!!", "success");
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e) => e.validationId == 260)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_show_drafts(false)
              FetchTheData(
                process.env.REACT_APP_BASE_API_PYTHON + "partsMaster?size=1000",
                "Delete"
              );
            } else {
              ShowMEWSPopup(response.message, "warning");
            }
          })
          .catch((error) => {
            ShowMEWSPopup( error.message, "error");
          });
      }
      set_multiple_codes([]);
      set_show_drafts(false);
    }
    set_delete_multiple(false);
  };
  const OneBack = () => {
    let assignDate = new Date(new Date().getTime() - 86400000);
    return (
      new Date(assignDate).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(assignDate).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(assignDate).getUTCDate())
    );
  };
  const AddTheItem = (status) => {
    let data = {
      effDateFrom: partsMasterObject.effDateFrom,
      effDateTo:
        status === true ? new Date("1900-01-01") : partsMasterObject.effDateTo,
      partCode: partsMasterObject.partCode,
      partDolFlag: partsMasterObject.partDolFlag,
      partName: partsMasterObject.partName,
      partPurchasePrice: partsMasterObject.partPurchasePrice,
      partUom: partsMasterObject.partUom,
      supplierDbId: Number(partsMasterObject.supplierName),
      createdBy: isAuthenticated === true ? user.name : null,
      createdTimestap: new Date().toISOString(),
      updatedBy: isAuthenticated === true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };

    console.log(post_url);
    if (Validations() > 0) {
      return;
    } else {
      axios
        .post(post_url, data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            // ShowMEWSPopup("✅Successfully added the item as draft", "Success");
            ShowMEWSPopup(
              `${
                clientSideValidations.find((e) => e.validationId == 257)
                  ?.shortMessage
              }`,
              "Success"
            );

            set_mews_popup_color("#28A745");
            set_open_add_modal(false);
            FetchTheData(
              process.env.REACT_APP_BASE_API_PYTHON + "partsMaster?size=1000",

              "Post"
            );
            set_open_add_modal(false);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          //return_mews_popup_color("#DC3545");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const GetServerDate = (_date) => {
    return (
      new Date(_date).getUTCFullYear() +
      "-" +
      ReturnThePaddedValues(Number(new Date(_date).getUTCMonth()) + 1) +
      "-" +
      ReturnThePaddedValues(new Date(_date).getUTCDate())
    );
  };
  const OnEditPart = () => {
    let deleteData = {
      effDateFrom: GetServerDate(partsMasterObjectDelete.effDateFrom),
      effDateTo: OneBack(),
      partCode: partsMasterObjectDelete.partCode,
      partDolFlag: partsMasterObjectDelete.partDolFlag,
      partName: partsMasterObjectDelete.partName,
      partPurchasePrice: Number(partsMasterObjectDelete.partPurchasePrice),
      partUom: partsMasterObjectDelete.partUom,
      supplierDbId: partsMasterObjectDelete.supplierName,
      updatedBy: isAuthenticated == true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    let data = {
      effDateFrom: GetServerDate(partsMasterObject.effDateFrom),
      effDateTo: GetServerDate(partsMasterObject.effDateTo),
      partCode: partsMasterObject.partCode,
      partDolFlag: partsMasterObject.partDolFlag,
      partName: partsMasterObject.partName,
      partPurchasePrice: partsMasterObject.partPurchasePrice,
      partUom: partsMasterObject.partUom,
      supplierDbId: Number(partsMasterObject.supplierName),
      createdBy: isAuthenticated === true ? user.name : null,
      createdTimestap: new Date().toISOString(),
      updatedBy: isAuthenticated === true ? user.name : null,
      updatedTimestamp: new Date().toISOString(),
    };
    if (Validations() > 0) {
      return;
    } else {
      axios
        .patch(post_url + "/" + selectpartDbId, deleteData)
        .then((response) => {
          if (response.status === 200) {
            axios
              .post(post_url, data)
              .then((response) => {
                if (response.status === 201) {
                  FetchTheData(
                    process.env.REACT_APP_BASE_API_PYTHON +
                      "partsMaster?size=1000",
                    "Patch"
                  );
                  // ShowMEWSPopup("✅Successfully updated the item", "Success");
                  ShowMEWSPopup(
                    `${
                      clientSideValidations.find((e) => e.validationId == 258)
                        ?.shortMessage
                    }`,
                    "Success"
                  );
                  set_mews_popup_color("#28A745");
                  set_open_edit_modal(false);
                  if (multiple_codes.length > 0) {
                    set_multiple_codes([]);
                  }
                  set_show_drafts(false)
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error.message + ":" + post_url + "/" + selectpartDbId);
          console.log(data);
          ShowMEWSPopup( error.message, "Error");
          set_mews_popup_color("#DC3545");
        });
    }
  };
  const Validations = () => {
    OnResetErrors();
    let temp = 0;
    let csv_count = 0;
    for (let i = 0; i < clientSideValidations.length; i++) {
      if (clientSideValidations[i].screenName === "Parts Master") {
        csv_count++;
        // eslint-disable-next-line default-case
        switch (clientSideValidations[i].fieldName) {
          case "partCode":
            if (
              clientSideValidations[i].validationType === "required" &&
              partsMasterObject.partCode.length == 0
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                partCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            if (
              clientSideValidations[i].validationType === "duplicate" &&
              new Date(partsMasterArray.findIndex(
                (e) => e.partCode === partsMasterObject.partCode
              ) != -1).getTime()===new Date("9999-12-31").getTime() ||    new Date(partsMasterArray.findIndex(
                (e) => e.partCode === partsMasterObject.partCode
              ) != -1).getTime()===new Date("1900-01-01").getTime()
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                partCodeError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "supplierDbId":
            if (
              clientSideValidations[i].validationType === "required" &&
              partsMasterObject.supplierName.length == 0
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                supplierNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "partName":
            if (
              clientSideValidations[i].validationType === "required" &&
              partsMasterObject.partName.length == 0
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                partNameError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "purchasePrice":
            if (
              clientSideValidations[i].validationType === "numeric" &&
              isNaN(partsMasterObject.partPurchasePrice)
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                partPurchasePriceError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "partUOM":
            if (
              clientSideValidations[i].validationType === "numeric" &&
              isNaN(partsMasterObject.partUom)
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                partUomError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
          case "effDateFrom":
            if (
              clientSideValidations[i].validationType === "date" &&
              new Date(partsMasterObject.effDateFrom).getTime() >=
                new Date(partsMasterObject.effDateTo).getTime() && new Date(partsMasterObject.effDateTo).getTime()!=new Date("1900-01-01").getTime()
            ) {
              temp++;
              setPartsMasterObjectErrors((partsMasterObjectErrors) => ({
                ...partsMasterObjectErrors,
                effDateFromError: clientSideValidations[i].shortMessage,
              }));
            }
            break;
        }
      }
    }
    return temp;
  };
  const OnDeleteSelectionClicked = () => {
    if (multiple_codes.length > 0) {
      set_show_delete_confirmations(true);
      set_delete_multiple(true);
    }
  };
  const OnClickShowDrafts = () => {
    if (show_drafts === false) {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = draft_array.length;
      console.log(draft_array.length);
      //Get The Total Pages
      total_pages.current = Math.ceil(
        draft_array.length / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      //Pagination Of Drafts
      let temp_display_draft_array = [];
      if (draft_array.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < draft_array.length; i++) {
          if (
            new Date(draft_array[i].effDateTo).getTime() ===
            new Date("1900-01-01").getTime()
          ) {
          temp_display_draft_array.push(draft_array[i]);
          }
        }
      } else {
        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }

        if (
          draft_array.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < draft_array.length;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          ) {
            temp_display_draft_array.push(draft_array[i]);
          }
        }
      }
      set_display_draft_array(temp_display_draft_array);
      set_show_drafts(true);
    } else {
      current_page.current = 1;
      //Get The Total Records
      total_records.current = partsMaster.length;
      let temp=0
      for (let i = 0; i < partsMaster.length; i++) {
        if (
          new Date(partsMaster[i].effDateTo).getTime() ===
          new Date("9999-12-31").getTime()
        ) {
        temp+=1
        }
      }
      //Get The Total Pages
      total_pages.current = Math.ceil(
        temp / process.env.REACT_APP_ITEMS_PER_PAGE
      );
      set_show_drafts(false);
      let temp_display_array = [];
      if (partsMaster.length <= process.env.REACT_APP_ITEMS_PER_PAGE) {
        current_page.current = 1;
        for (let i = 0; i < partsMaster.length; i++) {
          if (
            new Date(partsMaster[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
          temp_display_array.push(partsMaster[i]);
          }
        }
      } else {
        if (
          partsMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
            process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          current_page.current -= 1;
        }
        if (
          partsMaster.length <=
          current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
        ) {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < partsMaster.length;
            i++
          )  if (
            new Date(partsMaster[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
          temp_display_array.push(partsMaster[i]);
          }
        } else {
          for (
            let i =
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
              process.env.REACT_APP_ITEMS_PER_PAGE;
            i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
            i++
          )  if (
            new Date(partsMaster[i].effDateTo).getTime() ===
            new Date("9999-12-31").getTime()
          ) {
          temp_display_array.push(partsMaster[i]);
          }
        }
      }
      setPaginatedArray(temp_display_array);
    }
  };

  const FetchTheData = (_fetch_link, _action) => {
    setLoading(true);
    let temp_PartsMaster = [];
    let temp_draft_array = [];
    let tempNonDraftArray = [];
    axios
      .get(_fetch_link)
      .then((response) => {
        if (response.status === 200) {
          if (_action === "Get") {
            if (clientSideValidations.length > 0) {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 256)
                    ?.shortMessage
                }`,
                "Success"
              );
              set_mews_popup_color("#28A745");
            } else {
              ShowMEWSPopup(
                `${
                  clientSideValidations.find((e1) => e1.validationId == 389)
                    ?.shortMessage
                }`,
                "fail"
              );
              set_mews_popup_color("#DC3545");
            }
          }
          let temp_count = 0;
          for (let i = 0; i < response.data.length; i++) {
            temp_PartsMaster.push(response.data[i]);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("9999-12-31").getTime()
            ) {
              tempNonDraftArray.push(response.data[i]);
              temp_count++;
            }
            setPartsMasterArray(tempNonDraftArray);

            if (
              new Date(response.data[i].effDateTo).getTime() ===
              new Date("1900-01-01").getTime()
            ) {
              temp_draft_array.push(response.data[i]);
            }
          }
          set_draft_array(temp_draft_array);

          dispatch(populate_parts(temp_PartsMaster));
          total_records.current = temp_count;
          //Get The Total Pages
          total_pages.current = Math.ceil(
            temp_count / process.env.REACT_APP_ITEMS_PER_PAGE
          );
          //Pagination Of All Records
          let temp_display_array = [];
          if (
            tempNonDraftArray.length <= process.env.REACT_APP_ITEMS_PER_PAGE
          ) {
            current_page.current = 1;
            for (let i = 0; i < tempNonDraftArray.length; i++) {
              temp_display_array.push(tempNonDraftArray[i]);
            }
          } else {
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              current_page.current -= 1;
            }
            if (
              tempNonDraftArray.length <=
              current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE
            ) {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < tempNonDraftArray.length;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            } else {
              for (
                let i =
                  current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE -
                  process.env.REACT_APP_ITEMS_PER_PAGE;
                i < current_page.current * process.env.REACT_APP_ITEMS_PER_PAGE;
                i++
              ) {
                temp_display_array.push(tempNonDraftArray[i]);
              }
            }
          }
          set_draft_array(temp_draft_array);
          setPaginatedArray(temp_display_array);
          FetchTheSupplier(tempNonDraftArray);
          setLoading(false);
        } else {
          if (_action === "Get") {
            ShowMEWSPopup("⚠" + response.message, "Warning");
            set_mews_popup_color("#FFC107");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        ShowMEWSPopup( error.message, "Error");
        //return_mews_popup_color("#DC3545");
        set_mews_popup_color("#DC3545");
        setLoading(false);
      });
  };

  const [selectpartDbId, setSelectedPartDbId] = useState(0);
  //View Modal||Edit Modal
  const OpenModal = (prodProcessDbId, type) => {
    setSelectedPartDbId(prodProcessDbId);
    switch (type) {
      case "View":
        OnResetErrors();
        set_open_view_modal(true);
        break;
      case "Edit":
        OnResetErrors();
        set_open_view_modal(false);
        set_open_edit_modal(true);
        break;
      default:
        console.log("Undefined");
        break;
    }
    SupplyValuesToModal(prodProcessDbId);
  };
  const SupplyValuesToModal = (partDbId) => {
    // console.log(
    //   partsMaster.find((e) => e.partDbId == partDbId).effDateFrom
    // );
    let dateFrom = partsMaster.find((e) => e.partDbId == partDbId).effDateFrom;
    let dateTo = partsMaster.find((e) => e.partDbId == partDbId).effDateTo;
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partCode: partsMaster.find((e) => e.partDbId == partDbId).partCode,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      partCode: partsMaster.find((e) => e.partDbId == partDbId).partCode,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partName: partsMaster.find((e) => e.partDbId == partDbId).partName,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      partName: partsMaster.find((e) => e.partDbId == partDbId).partName,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partUom: partsMaster.find((e) => e.partDbId == partDbId).partUom,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      partUom: partsMaster.find((e) => e.partDbId == partDbId).partUom,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      supplierName: partsMaster.find((e) => e.partDbId == partDbId)
        .supplierName,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      supplierName: partsMaster.find((e) => e.partDbId == partDbId)
        .supplierName,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partDolFlag: partsMaster.find((e) => e.partDbId == partDbId).partDolFlag,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      partDolFlag: partsMaster.find((e) => e.partDbId == partDbId).partDolFlag,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      partPurchasePrice: partsMaster.find((e) => e.partDbId == partDbId)
        .partPurchasePrice,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      partPurchasePrice: partsMaster.find((e) => e.partDbId == partDbId)
        .partPurchasePrice,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      supplierName: partsMaster.find((e) => e.partDbId == partDbId)
        .supplierDbId,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      supplierName: partsMaster.find((e) => e.partDbId == partDbId)
        .supplierDbId,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      effDateFrom: dateFrom,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      effDateFrom: dateFrom,
    }));
    setPartsMasterObject((partsMasterObject) => ({
      ...partsMasterObject,
      effDateTo: dateTo,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      effDateTo: dateTo,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      createdBy: partsMaster.find((e) => e.partDbId == partDbId).createdBy,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      updatedBy: partsMaster.find((e) => e.partDbId == partDbId).updatedBy,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      createdTimestamp: partsMaster.find((e) => e.partDbId == partDbId)
        .createdTimestamp,
    }));
    setPartsMasterObjectDelete((partsMasterObjectDelete) => ({
      ...partsMasterObjectDelete,
      updatedTimestamp: partsMaster.find((e) => e.partDbId == partDbId)
        .updatedTimestamp,
    }));
  };

  const [sortStyle, setSortStyle] = useState("Descending");
  const AscendingDescendingFunction = (action) => {
    let SortPartMaster = [...partsMasterArray];
    if (sortStyle === "Descending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.partName;
        const b_res = action === "Code" ? b.partCode : b.partName;
        if (a_res > b_res) {
          return 1;
        } else if (a_res < b_res) {
          return -1;
        } else {
          return 0;
        }
      });

      setPaginatedArray(updatedSupplier);
      setSortStyle("Ascending");
      setPartsMasterArray(updatedSupplier);
    } else if (sortStyle === "Ascending") {
      let updatedSupplier = SortPartMaster.sort((a, b) => {
        const a_res = action === "Code" ? a.partCode : a.partName;
        const b_res = action === "Code" ? b.partCode : b.partName;
        if (a_res < b_res) {
          return 1;
        } else if (a_res > b_res) {
          return -1;
        } else {
          return 0;
        }
      });
      setPaginatedArray(updatedSupplier);
      setSortStyle("Descending");
    }
    let tempArray = [];
    if (SortPartMaster.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    } else {
      for (let i = 0; i < SortPartMaster.length; i++) {
        tempArray.push(SortPartMaster[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      SortPartMaster.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPartsMasterArray(SortPartMaster);
    setPaginatedArray(tempArray);
  };
  const [supplierPop, setSupplierPop] = useState(false);

  const OnClickSupplier = () => {
    // let temp = [];
    // if (selectedSupplier.length == 0) {
    //   for (let i = 0; i < supplierMaster.length; i++) {
    //     temp.push(supplierMaster[i].supplierDbId);
    //   }
    // } else {
    //   for (let i = 0; i < selectedSupplier.length; i++) {
    //     temp.push(selectedSupplier[i]);
    //   }
    // }
    // setSelectedSupplier(temp);
    if (partsMaster.length == 0) {
      setSupplierPop(false);
    } else {
      setSupplierPop(!supplierPop);
    }
  };
  const [selectedSupplier, setSelectedSupplier] = useState([]);

  const AddRemoveSupplier = (id) => {
    let tempSelectedSupplier = [...selectedSupplier];
    let supplierIndex = tempSelectedSupplier.findIndex(
      (e) => e.supplierDbId == id
    );
    if (supplierIndex == -1) {
      tempSelectedSupplier.push(
        supplierMaster.find((e) => e.supplierDbId == id)
      );
    } else {
      tempSelectedSupplier.splice(supplierIndex, 1);
    }
    setSelectedSupplier(tempSelectedSupplier);
  };
  const OnPressedClearClicked = (supplierDbId) => {
    let tempSelectedSupplier = [];
    if (selectedSupplier.length == 0) {
      for (let i = 0; i < supplierMaster.length; i++) {
        tempSelectedSupplier.push(supplierMaster[i].supplierDbId);
      }
    } else {
      tempSelectedSupplier.splice(
        tempSelectedSupplier.findIndex((e) => e === supplierDbId),
        1
      );
      setSelectedSupplier(tempSelectedSupplier);
    }
    setSelectedSupplier(tempSelectedSupplier);
  };

  const OnOkSupplier = () => {
    let temp = [...partsMaster];
    let updatedArray = [];
    for (let i = 0; i < selectedSupplier.length; i++) {
      for (let j = 0; j < temp.length; j++) {
        if (
          temp[j].supplierDbId === selectedSupplier[i].supplierDbId
          // supplierMaster.find((e) => e.supplierName == selectedSupplier[i])
          // .supplierDbId
        ) {
          if (
            new Date(temp[j].effDateTo).getTime() ==
            new Date("9999-12-31").getTime()
          )
            updatedArray.push(temp[j]);
        }
      }
    }
    let tempArray = [];
    if (updatedArray.length > process.env.REACT_APP_ITEMS_PER_PAGE) {
      for (let i = 0; i < process.env.REACT_APP_ITEMS_PER_PAGE; i++) {
        tempArray.push(updatedArray[i]);
      }
    } else {
      for (let i = 0; i < updatedArray.length; i++) {
        tempArray.push(updatedArray[i]);
      }
    }
    current_page.current = 1;
    total_pages.current = Math.ceil(
      updatedArray.length / process.env.REACT_APP_ITEMS_PER_PAGE
    );
    setPartsMasterArray(updatedArray);
    setPaginatedArray(tempArray);
    setSupplierPop(false);
  };
  const PartsScreenCode = useContext(ScreenCodeContext);
  const [showTooltip, setShowTooltip] = useState(0);

  return (
    <>
      {isAuthenticated == true ? (
        <div className="flex flex-col w-full ">
          <Header />
          <div className="container-fluid">
            <SuccessMessage
              mews_popup_color={mews_popup_color}
              open_edit_modal={open_edit_modal}
              show_mews_popup={show_mews_popup}
              multiple_codes={multiple_codes}
              mews_message={mews_message}
            />
            {open_add_modal == false &&
            open_edit_modal == false &&
            open_view_modal == false ? (
              <>
                <ManageSearch
                  name="Parts"
                  OnClickShowDrafts={OnClickShowDrafts}
                  show_drafts={show_drafts}
                  toolTipName={
                    screensToolTips.find((e) => e.toolTipDbId == 425)
                      ?.toolTipDesc
                  }
                  search_content={search_content}
                  onChangeSearchContent={onChangeSearchContent}
                  placeholder="Parts"
                />
                <div className="tablerounds">
                  <div className="tableboxes">
                    <table>
                      <colgroup>
                        <col span="1" style={{ width: "4%" }} />
                        <col span="1" style={{ width: "12%" }} />
                        <col span="1" style={{ width: "16%" }} />
                        <col span="1" style={{ width: "14%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>Sl.</th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Code")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Part Code
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                onClick={() =>
                                  AscendingDescendingFunction("Name")
                                }
                                className="ArrowIcons"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                              Part Name
                              <img
                                className="ArrowIcons opacity-0"
                                src={
                                  sortStyle === "Descending"
                                    ? ArrowDown
                                    : ArrowUp
                                }
                                alt=""
                              />
                            </div>
                          </th>
                          <th>
                            <div className="IconsSpace">
                              <img
                                id="Supplier"
                                className="Icons opacity-0"
                                alt=""
                              />
                              Supplier
                              <img
                                id="Supplier"
                                className="Icons"
                                onClick={() => OnClickSupplier()}
                                src={FilterIcon}
                                alt=""
                              />
                              {/* <label className="xl:hidden lg:hidden flex">
                              {" "}
                              &nbsp;
                              
                            </label> */}
                              {supplierPop === true ? (
                                <div className="filterPopPage">
                                  <div className="popupfile">
                                    <div className="popupfile-data">
                                      <div className="flex flex-col gap-2">
                                        <input
                                          id="Supplier"
                                          type="text"
                                          placeholder="Search"
                                          value={search_supplier}
                                          onChange={(e) =>
                                            OnChangeSearchSupplier(e)
                                          }
                                        />
                                        <div
                                          id="Supplier"
                                          className="Imageflex"
                                          onClick={() =>
                                            setSelectedSupplier(supplierArray)
                                          }
                                        >
                                          <img
                                            id="Supplier"
                                            className="Icons"
                                            src={
                                              supplierArray.length ==
                                              selectedSupplier.length
                                                ? CheckIcon
                                                : UnCheckIcon
                                            }
                                            alt=""
                                          />
                                          <p id="Supplier">Select All</p>
                                        </div>
                                      </div>
                                      <div className="popupfile-data">
                                        {search_supplier.length === 0
                                          ? supplierArray.map((item, index) => (
                                              <div
                                                id="Supplier"
                                                key={index}
                                                className="Imageflex"
                                                onClick={() =>
                                                  AddRemoveSupplier(
                                                    item.supplierDbId
                                                  )
                                                }
                                              >
                                                <img
                                                  id="Supplier"
                                                  className="Icons"
                                                  src={
                                                    selectedSupplier.findIndex(
                                                      (e) =>
                                                        e.supplierDbId ==
                                                        item.supplierDbId
                                                    ) != -1
                                                      ? CheckIcon
                                                      : UnCheckIcon
                                                  }
                                                  alt=""
                                                />
                                                <p id="Supplier">
                                                  {item.supplierName}
                                                </p>
                                              </div>
                                            ))
                                          : search_supplier.length > 0
                                          ? search_supplier_array.map(
                                              (item, index) => (
                                                <div
                                                  id="Supplier"
                                                  key={index}
                                                  className="Imageflex"
                                                  onClick={() =>
                                                    AddRemoveSupplier(
                                                      item.supplierDbId
                                                    )
                                                  }
                                                >
                                                  <img
                                                    id="Supplier"
                                                    className="Icons"
                                                    src={
                                                      selectedSupplier.findIndex(
                                                        (e) =>
                                                          e.supplierDbId ==
                                                          item.supplierDbId
                                                      ) !== -1
                                                        ? CheckIcon
                                                        : UnCheckIcon
                                                    }
                                                    alt=""
                                                  />
                                                  <p id="Supplier">
                                                    {item.supplierName}
                                                  </p>
                                                </div>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="file_button">
                                      <button
                                        id="Supplier"
                                        className="popupfile_button"
                                        onClick={() => OnOkSupplier()}
                                      >
                                        Ok
                                      </button>
                                      <button
                                        id="Supplier"
                                        className="popupfile_button"
                                        onClick={() => OnPressedClearClicked()}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </th>
                          <th>Part UoM</th>
                          <th>Purchase Price</th>
                          <th>Part Dol Flag</th>
                          <th>Effective From</th>
                          <th>Effective To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {show_drafts === false && search_content.length === 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.partDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {supplierMaster.length > 0 &&
                                  supplierMaster.findIndex(
                                    (e) => e.supplierDbId == item.supplierDbId
                                  ) != -1
                                    ? supplierMaster.find(
                                        (e) =>
                                          e.supplierDbId == item.supplierDbId
                                      ).supplierName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partUom}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partPurchasePrice}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partDolFlag == 1 ? "Yes" : "No"}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.partDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : show_drafts === true
                          ? display_draft_array.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.partDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {supplierMaster.length > 0 &&
                                  supplierMaster.findIndex(
                                    (e) => e.supplierDbId == item.supplierDbId
                                  ) != -1
                                    ? supplierMaster.find(
                                        (e) =>
                                          e.supplierDbId == item.supplierDbId
                                      ).supplierName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partUom}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partPurchasePrice}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partDolFlag == 1 ? "Yes" : "No"}
                                </td>

                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.partDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : search_content.length > 0
                          ? paginatedArray.map((item, index) => (
                              <tr
                                style={{
                                  backgroundColor:
                                    multiple_codes.findIndex(
                                      (e) => e === item.partDbId
                                    ) !== -1
                                      ? "#DAE0E4"
                                      : "#FFFFFF",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {index +
                                    1 +
                                    process.env.REACT_APP_ITEMS_PER_PAGE *
                                      (current_page.current - 1)}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partCode}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partName}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {supplierMaster.length > 0 &&
                                  supplierMaster.findIndex(
                                    (e) => e.supplierDbId == item.supplierDbId
                                  ) != -1
                                    ? supplierMaster.find(
                                        (e) =>
                                          e.supplierDbId == item.supplierDbId
                                      ).supplierName
                                    : null}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partUom}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partPurchasePrice}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {item.partDolFlag == 1 ? "Yes" : "No"}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {ReturnThePaddedValues(
                                    new Date(item.effDateFrom).getUTCDate()
                                  ) +
                                    "-" +
                                    ReturnThePaddedValues(
                                      Number(
                                        new Date(item.effDateFrom).getUTCMonth()
                                      ) + 1
                                    ) +
                                    "-" +
                                    new Date(item.effDateFrom).getUTCFullYear()}
                                </td>
                                <td
                                  style={{
                                    borderColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#FFFFFF"
                                        : "#DAE0E4",
                                    backgroundColor:
                                      multiple_codes.findIndex(
                                        (e) => e === item.partDbId
                                      ) !== -1
                                        ? "#DAE0E4"
                                        : "#FFFFFF",
                                  }}
                                  onClick={() =>
                                    select_deselect_the_record(item.partDbId)
                                  }
                                >
                                  {new Date(item.effDateTo).getUTCDate() +
                                    "-" +
                                    (Number(
                                      new Date(item.effDateTo).getUTCMonth()
                                    ) +
                                      1) +
                                    "-" +
                                    new Date(item.effDateTo).getUTCFullYear()}
                                </td>
                                <td>
                                  <IconComponent
                                    multiple_codes={multiple_codes}
                                    OpenModal={OpenModal}
                                    ShowDeleteConfirmation={
                                      ShowDeleteConfirmation
                                    }
                                    selectedFormDbId={item.partDbId}
                                  />
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <div className="flex flex-col items-center justify-center">
                      <p>
                        {" "}
                        {paginatedArray.length == 0 ? "No records found" : ""}
                      </p>
                    </div>
                    <div />
                  </div>
                  <TableButton
                    current_page={current_page.current}
                    total_pages={total_pages.current}
                    onPressPrevious={onPressPrevious}
                    onPressNext={onPressNext}
                    array={paginatedArray}
                    screenCode={
                      PartsScreenCode.find((e) => e.screenId === 84)?.screenCode
                    }
                    onClickAddItem={onClickAddItem}
                    OnDeleteSelectionClicked={OnDeleteSelectionClicked}
                    multiple_codes_delete={multiple_codes.length > 1}
                  />
                </div>
              </>
            ) : null}
            {/* //View box */}
            {open_view_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>View Parts Details</div>
                  <div>
                    {PartsScreenCode.find((e) => e.screenId === 86)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Part Code</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={partsMasterObject.partCode}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Part Name</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={partsMasterObject.partName}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Part Dol Flag</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          partsMasterObject.partDolFlag == 1 ? "Yes" : "No"
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Purchase Price</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={partsMasterObject.partPurchasePrice}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Part Uom</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={partsMasterObject.partUom}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Supplier</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          supplierMaster.findIndex(
                            (e) =>
                              e.supplierDbId === partsMasterObject.supplierName
                          )!=-1?supplierMaster.find(
                            (e) =>
                              e.supplierDbId === partsMasterObject.supplierName
                          ).supplierName:null
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective From</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            partsMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateFrom).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">Effective To</label>
                      </div>
                      <input
                        type="text"
                        disabled={true}
                        value={
                          new Date(
                            partsMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateTo).getUTCDate()
                          )
                        }
                        className="child_input"
                      />
                    </div>

                    {/* </div> */}
                  </div>
                </div>
                <ViewPageButton
                  close_modal={set_open_view_modal}
                  OpenModal={OpenModal}
                  selectedDbId={selectpartDbId}
                />
              </div>
            ) : null}
            {/* //Edit Popup column */}
            {open_edit_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Edit Parts Details</div>
                  <div>
                    {PartsScreenCode.find((e) => e.screenId === 87)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 126)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 9}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 126)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(9)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partCode}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup ">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 128)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 10}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 128)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(10)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partName}
                        onChange={(e) => onChangeValue(e, "partName", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 130)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partDolFlagError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 11}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 130)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(11)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={partsMasterObject.partDolFlag}
                        onChange={(e) => onChangeValue(e, "partDolFlag", null)}
                      >
                        <option value={null}>Select Dol Flag</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                    <div className="parent_popup ">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 132)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partPurchasePriceError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 12}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 132)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(12)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partPurchasePrice}
                        onChange={(e) =>
                          onChangeValue(e, "partPurchasePrice", null)
                        }
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 134)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partUomError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 13}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 134)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(13)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partUom}
                        onChange={(e) => onChangeValue(e, "partUom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 136)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.supplierNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 14}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 136)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(14)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={partsMasterObject.supplierName}
                        onChange={(e) => onChangeValue(e, "supplierName", null)}
                      >
                        <option value={null}>Select....</option>
                        {supplierMaster.length > 0
                          ? supplierMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.supplierDbId}>
                                  {item.supplierName}
                                </option>
                              ) : null
                            )
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 138)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.effDateFromError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 15}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 138)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(15)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            partsMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateFrom).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 140)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 16}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 140)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(16)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            partsMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateTo).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <EditPageButton
                  close_modal={set_open_edit_modal}
                  EditTheItem={OnEditPart}
                />
              </div>
            ) : null}
            {/* //Delete Popup column */}
            {show_delete_confirmations ? (
              <DeleteConfirmationModal
                CancelTheDeletion={CancelTheDeletion}
                OnDeleteItem={OnDeleteItem}
                item_to_delete={item_to_delete}
              />
            ) : null}
            {/* //Add Popup column */}
            {open_add_modal ? (
              <div className="all_popup_box">
                <div className="green_header_popup">
                  <div>Add Parts Details</div>
                  <div>
                    {PartsScreenCode.find((e) => e.screenId === 85)?.screenCode}
                  </div>
                </div>
                <div className="inputOverflow">
                  <div className="inputGrid">
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 125)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partCodeError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 1}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 125)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(1)}
                        onPointerLeave={() => setShowTooltip(0)}
                        onChange={(e) => onChangeValue(e, "partCode", null)}
                        type="text"
                        value={partsMasterObject.partCode}
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup ">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 127)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 2}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 127)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(2)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partName}
                        onChange={(e) => onChangeValue(e, "partName", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 129)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partDolFlagError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 3}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 129)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(3)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={partsMasterObject.partDolFlag}
                        onChange={(e) => onChangeValue(e, "partDolFlag", null)}
                      >
                        <option value={null}>Select Dol Flag</option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                    </div>
                    <div className="parent_popup ">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 131)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partPurchasePriceError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 4}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 131)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(4)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partPurchasePrice}
                        onChange={(e) =>
                          onChangeValue(e, "partPurchasePrice", null)
                        }
                        className="child_input"
                      />
                    </div>

                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 133)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.partUomError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 5}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 133)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(5)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="text"
                        value={partsMasterObject.partUom}
                        onChange={(e) => onChangeValue(e, "partUom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 135)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.supplierNameError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 6}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 135)
                            ?.toolTipDesc
                        }
                      />
                      <select
                        onPointerEnter={() => setShowTooltip(6)}
                        onPointerLeave={() => setShowTooltip(0)}
                        className="child_input"
                        value={partsMasterObject.supplierName}
                        onChange={(e) => onChangeValue(e, "supplierName", null)}
                      >
                        <option value={null}>Select....</option>
                        {supplierMaster.length > 0
                          ? supplierMaster.map((item, index) =>
                              new Date(item.effDateTo).getTime() ===
                              new Date("9999-12-31").getTime() ? (
                                <option key={index} value={item.supplierDbId}>
                                  {item.supplierName}
                                </option>
                              ) : null
                            )
                          : null}
                      </select>
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 137)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.effDateFromError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 7}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 137)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(7)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            partsMasterObject.effDateFrom
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateFrom
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateFrom).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateFrom", null)}
                        className="child_input"
                      />
                    </div>
                    <div className="parent_popup">
                      <div className="child_label_flex">
                        <label className="child_popup">
                          {
                            screensToolTips.find((e) => e.toolTipDbId == 139)
                              ?.labelName
                          }
                        </label>
                        <label className="error">
                          {partsMasterObjectErrors.effDateToError}
                        </label>
                      </div>
                      <InputToolTip
                        showTooltip={showTooltip == 8}
                        name={
                          screensToolTips.find((e) => e.toolTipDbId == 139)
                            ?.toolTipDesc
                        }
                      />
                      <input
                        onPointerEnter={() => setShowTooltip(8)}
                        onPointerLeave={() => setShowTooltip(0)}
                        type="date"
                        value={
                          new Date(
                            partsMasterObject.effDateTo
                          ).getUTCFullYear() +
                          "-" +
                          ReturnThePaddedValues(
                            Number(
                              new Date(
                                partsMasterObject.effDateTo
                              ).getUTCMonth()
                            ) + 1
                          ) +
                          "-" +
                          ReturnThePaddedValues(
                            new Date(partsMasterObject.effDateTo).getUTCDate()
                          )
                        }
                        onChange={(e) => onChangeValue(e, "effDateTo", null)}
                        className="child_input"
                      />
                    </div>
                  </div>
                </div>
                <AddPageButton
                  close_modal={set_open_add_modal}
                  AddTheItem={AddTheItem}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {loading == true ? (
        <div className="LoadingOpectity">
          <img className="LoadingGif" src={LoadingIcon} alt="" />
        </div>
      ) : null}
    </>
  );
}

export default Parts;
